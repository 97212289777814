import {configureStore} from '@reduxjs/toolkit'
import entitiesSlice from './entitiesSlice'
import userStateSlice from './userStateSlice'
import appStateSlice from './appStateSlice'

export default configureStore({
    reducer: {
        entities: entitiesSlice,
        userState: userStateSlice,
        appState: appStateSlice,
    }
})