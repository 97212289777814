import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCourseById,
  selectCourseStatus,
  fetchCourseFull,
} from "../../redux/entitiesSlice";

import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Rating from "@material-ui/lab/Rating";

import CircularProgress from "@material-ui/core/CircularProgress";

import CheckIcon from "@material-ui/icons/Check";

import PeopleIcon from "../../Icons/People";
import PlayCircleOutlineIcon from "../../Icons/Playack2";
import InstagramIcon from "@material-ui/icons/Instagram";

import CoursePlan from "./CoursePlan";
import RightBlock from "./RightBlock";
import { getCoursesItem } from "../../APIs/courses";

const useStyles = makeStyles({
  root: {
    padding: "20px 52px",
    margin: "0 auto",
    backgroundColor: "white",
  },

  courseTitle: {
    fontSize: 26,
    fontWeight: "normal",
    color: "#4B4B49",
    lineHeight: "30px",
    fontFamily: "Roboto",
  },

  courseAuthor: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "19px",
    color: "#4B4B49",
  },

  courseOverviewContainer: {
    marginTop: 7,
    display: "flex",
    justifyContent: "space-between",
    gap: 38,
  },

  courseImageContainer: {
    // background: 'linear-gradient(34.12deg, #845FA0 23.48%, #956CB6 57.29%)',
    display: "flex",
    // margin: '0 auto',
    justifyContent: "center",
    alignItems: "flex-start",
    // width: 780,
    // height: 420,
    overflow: "hidden",
  },

  courseImage: {
    width: "100%",
    height: "auto",
  },
  loadingRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },

  "@media (max-width: 960px)": {
    root: {
      padding: "20px",
    },
    courseImage: {
      borderRadius: 18,
    },
    courseOverviewContainer: {
      flexWrap: "wrap",
    },
  },
});

export const YouWillLearn = withStyles({
  root: {
    marginTop: 25,
    padding: "12px 0 22px 27px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "@media (max-width: 600px)": {
      padding: 0,
      boxShadow: "none",
    },
  },
  title: {
    fontSize: 22,
    lineHeight: "27px",
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    // flexWrap: 'wrap',
    // maxHeight: 210,
    marginTop: 10,
    alignItems: "flex-start",
  },
  listItem: {
    padding: 0,
    marginBottom: 15,
    width: "auto",
    flex: "1 1 auto",
  },
  listItemText: {
    fontSize: 15,
    lineHeight: "19px",
    fontFamily: "Montserrat",
  },
  listItemIcon: {
    minWidth: 38,
  },
})(({ classes }) => (
  <div className={classes.root}>
    <Typography variant="h3" className={classes.title}>
      Nimalarni o’rganasiz:
    </Typography>
    <List className={classes.list}>
      {[
        "Ingliz tilida erkin va ravon fikrlashni va so’zlashishni.",
        "So’zlashish va yozish uchun eng muhim bo’lgan grammatik mavzularni.                ",
        "Sizga ravon gapirishga yordam beradigan talaffuz qoidalari va sirlarini.                ",
        "O’zingizni tanishtirish, Oila, ish joyi, ob-havo, qilingan ishlar, kelajakdagi loyihalar va boshqa 12 dan ortiq umumiy mavzularda suhbatlar qurishni.                ",
        "Biografiya, elektron pochta xabarlari, ijtimoiy tarmoqlardagi xabarlar, taklifnomalar, minnatdorchilik xatlari va boshqalarni yozishni.                ",
        `Bundan tashqari, siz ko'plab audio va video materiallar asosida muhim bo’lgan so'z boyligingizni rivojlantirasiz.                `,
      ].map((text, index) => (
        <ListItem key={index} disableGutters className={classes.listItem}>
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText
            primary={text}
            classes={{ primary: classes.listItemText }}
          />
        </ListItem>
      ))}
    </List>
  </div>
));

export const Description = withStyles({
  root: {
    marginTop: 23,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    padding: "17px 27px",
    "@media (max-width:600px)": {
      padding: 0,
      boxShadow: "none",
    },
  },
  title: {
    fontWeight: 600,
    fontSize: 22,
    lineHeight: "27px",
  },
  description: {
    fontWeight: 500,
    fontSize: 15,
    lineHeight: "20px",
    color: "#393939",
    marginTop: 21,
  },
})(({ classes }) => (
  <div className={classes.root}>
    <Typography variant="h3" className={classes.title}>
      Kurs haqida:
    </Typography>
    <Typography className={classes.description}>
      Ingliz tili dunyodagi eng ko'p ishlatiladigan va keng tarqalgan til
      hisoblanadi. Dunyo bo'ylab unda har besh kishidan biri gaplasha oladi yoki
      uni tushunadi. <br /> 
      
      <br />Ingliz tilini bilishingiz yuqori maoshli ish topishingizda
      yoki ish faoliyatingizda o’sish imkoniyatlaringizni oshirishda katta
      yordam beradi. Ayniqsa, xorijiy kompaniyalarda yoki chet elda ishlash
      niyatingiz bo’lsa ayni muddao. Shuningdek, ingliz tili sizga sayohat va
      muloqot uchun juda qo’l keladi. <br /> 
      <br />Ushbu kurs ingliz tilini o’rganishni endi
      boshlayotganlar, ko’p vaqt mobaynida bu tilni o’rganishda qiyinchiliklarga
      duch kelganlar uchun, lekin sayohat, biznes, chet elda ta’lim olishni va
      boshqa maqsadlarda ingliz tilini o’rganishni istaganlar uchun
      mo'ljallangan. <br /> 
      <br />Har bir darsning tematik yondashuvi tinglovchilarga tilni
      o’zlashtirishda zarur bo’lgan so’z boyligini shakllantirishga
      mo’ljallangan. Bundan tashqari tinglovchilar to’gri talaffuz qilish
      sirlarini, muhim bo’lgan grammatikaga oid mavzularni o’rganadilar, va
      albatta eshitish, yozish, o’qish kabi til o’rganishning muhim tarkibiy
      qismlarini rivojlantiradilar. Eng asosiysi, ingliz tilida fikrlashni
      o’rganadilar. <br />
      <br />Edvi.uz sizga ingliz tilini o’rganishingizda kerakli barcha
      bilimlarni boshlang’ich ko’nikmalardan to mukammal darajaga
      erishguningizga qadar taqdim etadi. <br />
      <br /><strong>Kurs kim uchun mo’ljallangan:</strong> <br /> 
      <br />Ingliz tilini boshidan (noldan) o'rganmoqchi bo'lganlar uchun. O'z jadvaliga va
      joylashuv manziliga ko'ra ingliz tilini onlayn o'rganishni, mutaxassis
      tomonidan professional nazorat ostida bo'lishini istaganlar uchun. <br /> 
      <br />Uzoq vaqt davomida ingliz tilini o'rgangan, ammo Grammatika va So'zlashishda
      muammolarga duch keladigan har bir kishi uchun. <br /> 
      <br />Chet elda ishlash yoki o'qish uchun ingliz tilini o'rganishni boshlamoqchi bo’lgan har bir inson
      uchun.
    </Typography>
  </div>
));

const StudentsReviews = withStyles({
  root: {
    marginTop: 23,
    padding: "17px 31px",
  },
  title: {
    fontSize: 22,
    lineHeight: "27px",
    fontWeight: 600,
    marginBottom: 30,
  },
  fullName: {
    fontSize: 13,
    lineHeight: "16px",
    fontWeight: 600,
  },
  description: {
    fontSize: 13,
    lineHeight: "16px",
    color: "#4B4B49",
  },
  date: {
    fontSize: 12,
    lineHeight: "15px",
    color: "#A8A8A8",
  },
  ratingContainer: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: 32,
  },
  divider: {
    marginTop: 15,
  },
})(({ classes, reviews }) => (
  <section className={classes.root}>
    <Typography variant="h4" className={classes.title}>
      Students reviews
    </Typography>
    <List>
      {reviews.map((review) => (
        <ListItem key={review.id}>
          <ListItemAvatar>
            <Avatar
              alt={review.fullName}
              src={review.avatarUrl}
              className={classes.avatar}
            />
          </ListItemAvatar>
          <div>
            <ListItemText
              primary={review.fullName}
              classes={{ primary: classes.fullName }}
            />
            <div className={classes.ratingContainer}>
              <Rating
                value={review.rated}
                precision={0.5}
                size="small"
                readOnly
              />
              <Typography
                variant="subtitle2"
                component="span"
                className={classes.date}
              >
                {review.date.toString()}
              </Typography>
            </div>
            <ListItemText
              secondary={review.description}
              classes={{ secondary: classes.description }}
            />
            <Divider className={classes.divider} />
          </div>
        </ListItem>
      ))}
    </List>
  </section>
));

export const AboutAuthor = withStyles({
  root: {
    marginTop: 23,
    // padding: '17px 31px',
  },
  container: {
    display: "flex",
    gap: 30,
  },
  photoContainer: {
    backgroundColor: "#E3E3E3",
    width: 170,
    height: 170,
    // position: 'relative',
  },
  photo: {
    // position: 'absolute',
    // bottom: 0,
    width: "auto",
    height: 170,
  },
  details: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "16px",
    color: "#6A6969",
  },
  fullName: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: "22px",
  },
  profession: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "17px",
    color: "#6A6969",
    marginTop: 4,
    marginBottom: 20,
  },
  about: {
    whiteSpace: "pre-wrap",
    fontFamily: "Montserrat",
    fontSize: 13,
    lineHeight: "15px",
    color: "#1D1D1D",
  },
  row: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    marginTop: 10,
  },
  socialIcon: {
    fontSize: 17,
    margin: "1px 0 0 -1px",
  },
  title: {
    fontSize: 22,
    lineHeight: "27px",
    fontWeight: 600,
    marginBottom: 30,
  },
  description: {
    fontWeight: 500,
    fontSize: 15,
    lineHeight: "20px",
    color: "#393939",
    marginTop: 21,
  },
})(({ classes, author }) => (
  <section className={classes.root}>
    <Typography variat="h4" className={classes.title}>
      Muallif
    </Typography>
    <div className={classes.container}>
      <div className={classes.photoContainer}>
        <img
          alt={author.fullName}
          src={author.photoUrl}
          className={classes.photo}
        />
      </div>
      <div>
        <Typography className={classes.fullName}>{author.fullName}</Typography>
        <Typography className={classes.profession}>
          {author.profession}
        </Typography>
        <div className={classes.row}>
          <PeopleIcon />
          <Typography variant="subtitle2" className={classes.details}>
            {Intl.NumberFormat("en-US").format(author.studentsCount)}{" "}
            o`quvchilar
          </Typography>
        </div>
        <div className={classes.row}>
          <PlayCircleOutlineIcon />
          <Typography variant="subtitle2" className={classes.details}>
            {author.coursesCount} kurs
          </Typography>
        </div>
        {author.socialNetworks.map((sn) => {
          return (
            <div className={classes.row} key={sn.id}>
              <InstagramIcon className={classes.socialIcon} color="primary" />
              <Typography variant="subtitle2" className={classes.details}>
                {sn.username}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
    <div>
      <Typography className={classes.description}>
      • Volinkin.uz platformasi muallifi va asoschilaridan biri.<br />
      • Xalqaro TESOL / TEFL sertifikati sohibi.<br />
      • 10 yildan ortiq tajribaga ega ingliz tili o`qituvchisi. Hozirga kelib 1000 dan ortiq shogirdlar yetishtirgan.<br />
      • Ko’plab shogirdlari chet el oliygohlarida (AQSH, Avstraliya, Germaniya, Polsha, Latviya, Rossiya) tahsil oladi va istiqomat qiladi.<br />
      • Ingliz tilini o’rganish va rivolantirish bo’yicha ko’plab loyihalar muallifi va spikeri.<br />
      • Xorijiy IT kompaniyasida direktor o’rinbosari lavozimida bir necha yil faoliyat yuritgan.<br />
      </Typography>
    </div>
  </section>
));

const CourseOverview = () => {
  //state
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const courseID = params["courseID"];
  console.log('params CourseOverview', params["courseID"]);
  const course = useSelector((state) => selectCourseById(state, courseID));
  const courseStatus = useSelector((state) =>
    selectCourseStatus(state, courseID)
  );

  // useEffect(() => {
  //   if (courseStatus === "idle") {
  //     dispatch(fetchCourseFull(courseID));
  //   }
  // }, [courseID, courseStatus, dispatch]);

  useEffect(() => {
    getCoursesItem(courseID)
    .then(res => {
      setLoading(false)
      setData(res);
      console.log('getCoursesItem res course overview', res);
    })
    .catch(err => {
      setLoading(false)
      console.log('catch err', err);
    })
    setLoading(false)
  }, [])
  

  if (loading) {
    return (
      // <Dialog
      //     open
      //     PaperProps={{
      //         elevation: 0,
      //         style: {backgroundColor: 'transparent'}
      //     }}
      // >
      //     <CircularProgress color="primary" size={100}/>
      // </Dialog>
      <div className={classes.loadingRoot}>
        <CircularProgress size={100} />
      </div>
    );
  }

  const author = {
    fullName: "Farukh Korchiyev",
    photoUrl: "/images/teacher2.jpg",
    profession: "English teacher",
    studentsCount: 1000,
    coursesCount: 1,
    socialNetworks: [
      {
        id: 1,
        type: "instagram",
        username: "@volinkin.uz",
      },
    ],
    about:
      "• Volinkin.uz platformasi muallifi va asoschilaridan biri. • TESOL/ TEFL sertifikati sohibi. 10 yillidan ortiq tajribaga ega ingliz tili o`qituvchisi. Hozirga kelib 1000 dan ortiq shogirtlar yetishtirgan. Ular orasida yuzlab talabalarini Beginner (boshlang’ich) darajasidan to Advanced (eng yuqori) darajasigacha olib chiqishga erishgan.Ko’plab shogirdlari chet el oliygohlarida (AQSH, Avstraliya, Germaniya, Polsha, Latviya, Rossiya) tahsil oladi va istiqomat qiladi. Ingliz tilini o’rganish va rivolantirish bo’yicha ko’plab loyihalar muallifi va   spikeri. Xalqaro IT kompaniyasida direktor o’qinbosari lavozimida bir necha yil faoliyat yuritgan, bilim berishni Afzal ko’rishni istagani uchun lavozimidan voz kechgan.",
  };
  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.courseTitle}>
        sawd
      </Typography>
      <Typography variant="subtitle1" className={classes.courseAuthor}>
        Kurs muallifi: Farukh Korchiyev
      </Typography>
      <div className={classes.courseOverviewContainer}>
        <div className={classes.courseImageContainer}>
          <img src="/images/teacher@2x.jpg" className={classes.courseImage} />
        </div>
        <RightBlock />
      </div>
      <YouWillLearn />
      <Description />
      {/* <CoursePlan units={20} /> */}
      {/* <StudentsReviews reviews={[]}/> */}
      <AboutAuthor author={author} />
    </div>
  );
};

export default CourseOverview;

Number.prototype.toHHMMSS = function () {
  var sec_num = this;
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};
