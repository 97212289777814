import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncSignIn,
  selectUserProfile,
  selectUserStateStatus,
} from "../../redux/userStateSlice";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CoursesList from "./CoursesList";
import {
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Dialog,
  Hidden,
} from "@material-ui/core";
import clsx from "clsx";
import { showSignIn, showSignUp } from "../../redux/appStateSlice";
import { FaInstagram, FaTelegram } from "react-icons/fa";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import VideoPlayer from "../Course/VideoPlayer";

const UnderlinedLabel = withStyles((theme) => ({
  text: {
    fontFamily: "Montserrat, Arial, sans-serif",
    fontSize: 30,
    fontWeight: 600,
    lineHeight: "37px",
    letterSpacing: "0em",
  },
  underline: {
    width: 80,
    height: 0,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },
}))(({ classes, className, text }) => (
  <div className={className}>
    <Typography variant="h2" className={classes.text}>
      {text}
    </Typography>
    {/* <div className={classes.underline} /> */}
  </div>
));

const CustomOutlinedButton = withStyles({
  root: {
    width: 166,
    height: 40,
    borderRadius: 28,
    border: "1px solid #178FC2",
  },
  label: {
    color: "#178FC2",
  },
})(({ classes, children, ...other }) => (
  <Button
    classes={{
      root: classes.root,
      label: classes.label,
    }}
    {...other}
  >
    {children}
  </Button>
));

const BenefitsCard = withStyles({
  root: {
    width: 295,
    // height: 295,
    flex: "0 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #C9C9C9",
    boxShadow: "0px 4px 28px rgba(0, 0, 0, 0.17)",
    borderRadius: 10,
    padding: "0 18px 18px",
  },
  topBorder: {
    height: 8,
    width: 295,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  icon: {
    marginTop: 21,
    marginBottom: 18,
  },
  title: {
    textAlign: "center",
    fontSize: 17,
    fontWeight: 600,
    marginTop: 18,
    marginBottom: 23,
  },

  description: {
    fontSize: 13,
    // textAlign: "center",
    color: "#707880",
    margin: "0 10px",
    lineHeight: "20px",
  },
})(({ classes, color, icon, title, description }) => (
  <div className={classes.root}>
    <div className={classes.topBorder} style={{ backgroundColor: color }} />
    <img className={classes.icon} src={icon} alt="icon" />
    <h4 className={classes.title}>{title}</h4>
    <p className={classes.description}>{description}</p>
  </div>
));

const FeedbackCard = withStyles({
  root: {
    position: "relative",
    minWidth: 245,
    borderRadius: 8,
    backgroundColor: "white",
    border: "1px solid #EDEDED",
    boxShadow: "0px 10px 29px rgba(0, 0, 0, 0.17)",
    marginTop: 60,
  },
  avatar: {
    width: 160,
    height: 160,
    borderRadius: 178,
    borderColor: "2px solid white",
    filter: "drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25))",
    display: "block",
    margin: "-61px auto 0 auto",
  },
  fullName: {
    textAlign: "center",
  },
  feedback: {
    fontSize: 14,
    lineHeight: "23px",
    textAlign: "center",
    fontWeight: 500,
    color: "#5B5B5B",
    padding: "0px 24px 10px 24px",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px 17px 13px 0",
  },
  username: {
    fontSize: 11,
    lineHeight: "13px",
    color: "#393939",
    marginLeft: 5,
  },
  bottomBorder: {
    height: 13,
    backgroundColor: "#178FC2",
    borderRadius: "0px 0px 7px 7px",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
})(({ classes, feedback }) => (
  <div className={classes.root}>
    <img className={classes.avatar} src={feedback.profilePhoto} alt="profile" />
    <h4 className={classes.fullName}>{feedback.fullName}</h4>
    <p className={classes.feedback}>{feedback.feedback}</p>
    <div className={classes.footer}>
      <img src={feedback.socialIcon} alt="social_network" />
      <p className={classes.username}>{feedback.username}</p>
    </div>
    <div className={classes.bottomBorder} />
  </div>
));

const Info = withStyles({
  root: {
    marginRight: "20px",
  },
  title: {
    fontSize: 15,
    // lineHeight: '33px',
    fontWeight: 600,
    color: "#004766",
  },
  subtitle: {
    fontSize: 15,
    width: 145,
    // lineHeight: '33px',
    color: "#393939",
  },
})(({ classes, title, subtitle }) => (
  <div className={classes.root}>
    <Typography className={classes.title}>{title}</Typography>
    <Typography className={classes.subtitle}>{subtitle}</Typography>
  </div>
));

const PricingCard1 = withStyles({
  root: {
    width: 350,
    // height: 266,
    backgroundColor: "#f9f9f900",
    borderRadius: 12,
    boxShadow: "0px 8px 25px #1752e0",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    padding: 10,
    "@media (max-width: 600px)": {
      marginBottom: 15
    },
  },
  icon: {
    marginTop: 26,
    marginBottom: 52,
  },
  line: {
    backgroundColor: "#aeaeae",
    height: 1,
    width: "100%",
  },
  signInButtonRoot: {
    borderColor: "#000",
    borderRadius: 8,
    marginRight: 10,
    color: "#000",
    backgroundColor: "#fff",
  },
  title: {
    color: "#fff",
    fontSize: 25,
    fontWeight: "bolder"
  },
  label:{
    color: "#fff",
    fontSize: 18,
    fontWeight: "bolder"
  },
  sublabel:{
    color: "#000"
  }
})(({ classes, title, subtitle }) => {
  const dispatch = useDispatch();

  const handleSignUp = () => {
    dispatch(showSignUp())
  }
return (
  <div className={classes.root}>
    <Typography align="center" variant="h6" className={classes.title}>
      STANDART
    </Typography>
    <div className={classes.line}></div>
    {/* <Typography variant="subtitle1">500 000</Typography> */}
    <Typography align="center" style={{color: "white"}} variant="h4">300 000 so`m</Typography>
    <div className={classes.line}></div>
    <Typography style={{ color: "#fff", fontSize: 20, fontWeight: 'bolder', marginBottom: 5, marginTop: 5}}>
      Kurs tarkibiga to'liq kirish
    </Typography>
    <Typography className={classes.label}>Ta'lim platformasi</Typography>
    <Typography className={classes.sublabel}>
    -  Darslar boshlanganidan kurs dasturiga ikki oylik kirish
    </Typography>
    <Typography className={classes.label}>Qo'shimcha materiallar</Typography>
    <Typography className={classes.sublabel}>Tekshirish va qo'llanma jadvallari</Typography>
    <Typography className={classes.label}>Kuratorlar tomonidan</Typography>
    <Typography className={classes.label}>otrabotkalarni tekshirish</Typography>
    <Typography className={classes.sublabel}>
      Otrabotkalarni va uy vazifalarini tekshirish va ma'lumotlar bazasiga
      kirish
    </Typography>
    <Typography className={classes.label}>
      Zoom orqali UMUMIY jonli efirda haftalik savol javoblar
    </Typography>
    <Typography className={classes.sublabel}>Umumiy chat</Typography>
    <Typography className={classes.label}>Kurs ishtirokchilarining umumiy guruhi</Typography>
    <Button
      classes={{
        root: classes.signInButtonRoot,
        // label: clsx(
        //   classes.disableTextTransform,
        //   classes.signInButtonText
        // ),
      }}
      variant="contained"
      color="primary"
      onClick={handleSignUp}
    >
      SOTIB OLISH
    </Button>
  </div>
  )}
);


const PricingCard2 = withStyles({
  root: {
    width: 350,
    // height: 266,
    backgroundColor: "#f9f9f900",
    borderRadius: 12,
    boxShadow: "0px 8px 25px #1752e0",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    padding: 10,
    "@media (max-width: 600px)": {
      marginBottom: 15
    },
  },
  icon: {
    marginTop: 26,
    marginBottom: 52,
  },
  line: {
    backgroundColor: "#aeaeae",
    height: 1,
    width: "100%",
  },
  signInButtonRoot: {
    borderColor: "#000",
    borderRadius: 8,
    marginRight: 10,
    color: "#000",
    backgroundColor: "#fff",
  },
  title: {
    color: "#fff",
    fontSize: 30,
    fontWeight: "bolder"
  },
  label:{
    color: "#000",
    fontSize: 18,
    fontWeight: "bolder"
  },
  sublabel:{
    color: "#000"
  }
})(({ classes, title, subtitle }) => {
  const dispatch = useDispatch();

  const handleSignUp = () => {
    dispatch(showSignUp())
  }
return (
  <div className={classes.root}>
    <Typography align="center" variant="h6" className={classes.title}>
      VIP
    </Typography>
    <div className={classes.line}></div>
    {/* <Typography variant="subtitle1">500 000</Typography> */}
    <Typography align="center" style={{color: "white"}} variant="h4">1 100 000 so`m</Typography>
    <div className={classes.line}></div>
    <Typography style={{ color: "#fff", fontSize: 20, fontWeight: 'bolder', marginBottom: 5, marginTop: 5}}>
      Kurs tarkibiga to'liq kirish
    </Typography>
    <Typography className={classes.label}>Ta'lim platformasi</Typography>
    <Typography className={classes.sublabel}>
    -  Darslar boshlanganidan kurs dasturiga ikki oylik kirish
    </Typography>
    <Typography className={classes.label}>Qo'shimcha materiallar</Typography>
    <Typography className={classes.sublabel}>Tekshirish va qo'llanma jadvallari</Typography>
    <Typography className={classes.label}>Kuratorlar tomonidan</Typography>
    <Typography className={classes.label}>otrabotkalarni tekshirish</Typography>
    <Typography className={classes.sublabel}>
      Otrabotkalarni va uy vazifalarini tekshirish va ma'lumotlar bazasiga
      kirish
    </Typography>
    <Typography className={classes.label}>
      Zoom orqali UMUMIY jonli efirda haftalik savol javoblar
    </Typography>
    <Typography className={classes.sublabel}>Umumiy chat</Typography>
    <Typography className={classes.label}>Kurs ishtirokchilarining umumiy guruhi</Typography>
    <Button
      classes={{
        root: classes.signInButtonRoot,
        // label: clsx(
        //   classes.disableTextTransform,
        //   classes.signInButtonText
        // ),
      }}
      variant="contained"
      color="primary"
      onClick={handleSignUp}
    >
      SOTIB OLISH
    </Button>
  </div>
  )}
);

const FirstRow = withStyles({
  root: {
    background: "linear-gradient(39.35deg, #ffffff -4.47%, #1752e0 98.23%)",
    height: 380,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    overflowY: "hidden",
    // position: "relative",
  },

  caption: {
    // minWidth: 460,
    // height: '100%',
    // padding: '110px 0 0 66px'
    // background: '#b3872540',
    textAlign: "end",
    marginRight: "55px",
    fontFamily: "Montserrat, Arial, sans-serif",
    color: "white",
    zIndex: 100,
    "@media (max-width: 600px)": {
      marginRight: "10px",
    },
  },

  title: {
    fontSize: 40,
    // lineHeight: '52px',
    fontWeight: 700,
    "@media (max-width: 1110px)": {
      fontSize: 40,
    },
    "@media (max-width: 1000px)": {
      fontSize: 22,
    },
  },
  title1: {
    fontSize: 40,
    // lineHeight: '52px',
    fontWeight: 700,
    "@media (max-width: 1110px)": {
      fontSize: 40,
    },
    "@media (max-width: 1000px)": {
      fontSize: 32,
    },
  },
  title2: {
    fontSize: 40,
    // lineHeight: '52px',
    fontWeight: 700,
    "@media (max-width: 1110px)": {
      fontSize: 40,
    },
    "@media (max-width: 1000px)": {
      fontSize: 17,
    },
  },
  subtitle: {
    fontSize: 20,
    // lineHeight: '46px',
    fontWeight: 600,
    "@media (max-width: 1110px)": {
      fontSize: 16,
    },
    "@media (max-width: 1000px)": {
      fontSize: 12,
    },
  },

  img: {
    // position: "absolute",
    width: "50%",
    height: "100%",
    backgroundImage: "url(/images/3.jpg)",
    backgroundRepeat: "round",
    // backgroundPositionX: '350px',
    // '@media (max-width: 1110px)': {
    //     backgroundPositionX: '240px',
    // },
    // "@media (max-width: 1000px)": {
    //   width: "100%",
    //   position: "absolute",
    // },
    // '@media (max-width: 920px)': {
    //     backgroundPositionX: 0,
    //     position: 'static',
    // },
    // '@media (max-width: 875px)': {
    //     backgroundPositionX: -100,
    // },
    // '@media (max-width: 775px)': {
    //     backgroundPositionX: -140,
    // },
    // '@media (max-width: 730px)': {
    //     display: 'none'
    // }
  },

  signInButtonRoot: {
    borderColor: "#fff",
    borderRadius: 8,
    marginRight: 10,
    background: "#fff",
  },

  signInButtonText: {
    color: "#000",
  },

  authForm: {
    backgroundColor: "white",
    minWidth: 280,
    maxWidth: 280,
    height: 253,
    marginRight: 60,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px 16px",
    zIndex: 100,
    position: "relative",
    top: -20,
    "@media (max-width: 920px)": {
      position: "static",
    },
    "@media (max-width: 730px)": {
      margin: "0 auto",
      maxWidth: "300px",
      width: "100%",
    },
  },
})(({ classes }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const profile = useSelector(selectUserProfile);
  const loginStatus = useSelector(selectUserStateStatus);
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(asyncSignIn(email, password));
  };
  const handleSignIn = () => {
    dispatch(showSignUp());
  };
  return (
    <div className={classes.root}>
      {profile ? (
        <Hidden>
          <div className={classes.img} />
          <div className={classes.caption}>
            <div className={classes.title1}>Volinkin.uz</div>
            {/* <div className={classes.title2}>Jozibali ayollar</div> */}
            <div className={classes.title}>platformasiga</div>
            <div className={classes.title}>xush kelibsiz</div>
          </div>
        </Hidden>
      ) : (
        <>
          <div className={classes.img} />
          <div className={classes.caption}>
            <div className={classes.title1}>Volinkin.uz</div>
            <div className={classes.title}>platformasiga</div>
            <div className={classes.title}>xush kelibsiz</div>

            <Button
              classes={{
                root: classes.signInButtonRoot,
                label: clsx(classes.signInButtonText),
              }}
              variant="outlined"
              color="primary"
              onClick={handleSignIn}
            >
              Ro`yhatdan o`tish
            </Button>
          </div>
        </>
      )}

      {/* <Dialog
                open={loginStatus === 'pending'}
                PaperProps={{
                    elevation: 0,
                    style: {backgroundColor: 'transparent'}
                }}
                disableBackdropClick
            >
                <CircularProgress/>
            </Dialog> */}
    </div>
  );
});

const FreeVideos = withStyles({
  root: {
    width: "100%",
    paddingLeft: 35,
    display: "flex",
    justifyContent: "space-around",
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 20,
    "@media (max-width: 600px)": {
      justifyContent: "flex-start",
      marginLeft: 5,
      paddingLeft: 5,
      overflowX: "auto",
    },
  },
  row: {
    display: "flex",
    minWidth: 400,
    maxWidth: 246,
    marginRight: 5,
    "@media (max-width: 600px)": {
      // flexDirection: "column",
      minWidth: 300,
      maxWidth: 246,
      marginLeft: 5,
      paddingLeft: 5,
    },
  },
  title: {
    marginLeft: 60,
    textAlign: "center",
    "@media (max-width: 600px)": {
      marginLeft: 20,
    },
  },
  videoPlayer: {
    width: "100%",
    // height: "100%",
    marginRight: 20,
    marginBottom: 20,
    borderRadius: 10,
    border: "2px solid #fff",
  },
  "@media (min-width: 600px)": {
    videoPlayer: {},
  },
})(({ classes }) => {
  return (
    <>
      <div className={classes.title}>
        <Typography style={{ fontSize: 30, color: "#fff", marginTop: 10 }}>
          Mixail Volinkindan maslahatlar bepul lekin juda qiymatli{" "}
        </Typography>
      </div>
      <div className={classes.root}>
        <div className={classes.row}>
          <VideoPlayer
            src="http://139.59.174.35/uploads/videos/a86ca594c0fa4dbc079c3c59d7b6c440.mp4"
            className={classes.videoPlayer}
            controls
          />
        </div>
        <div className={classes.row}>
          <VideoPlayer
            src="http://139.59.174.35/uploads/videos/a7e1980b4d9cf4a761f0241851d04722.mp4"
            className={classes.videoPlayer}
            controls
          />
        </div>
        <div className={classes.row}>
          <VideoPlayer
            src="http://139.59.174.35/uploads/videos/6da188b8f6dbabbbe76900aa19f81c9e.mp4"
            className={classes.videoPlayer}
            controls
          />
        </div>
      </div>
    </>
  );
});

const ThirdRow = withStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#e9d1a0",
    padding: "0 150px 30px",
  },
  label: {
    paddingTop: 70,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingBottom: 30,
  },

  title: {
    fontSize: 35,
    fontWeight: 500,
    fontFamily: "Montserrat, Arial, sans-serif",
    lineHeight: "42px",
    color: "#004766",
    maxWidth: 600,
    marginTop: 35,
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Montserrat, Arial, sans-serif",
    lineHeight: "23px",
    color: "#555",
    marginTop: 44,
    maxWidth: 500,
  },
  img: {
    height: 400,
    width: "auto",
    borderRadius: 18,
  },
  "@media (max-width: 640px)": {
    // img: {
    //     display: 'none',
    // },
    root: {
      paddingLeft: 35,
      paddingRight: 100,
    },
  },
  "@media (max-width: 400px)": {
    img: {
      marginTop: 40,
    },
    root: {
      paddingLeft: 35,
      paddingRight: 35,
    },
    title: {
      // textAlign: 'center',
      fontSize: 23,
      lineHeight: 1,
    },
    subtitle: {
      marginTop: 20,
      textAlign: "justify",
    },
  },
})(({ classes }) => (
  <div className={classes.root}>
    <UnderlinedLabel text="Maqsadimiz" className={classes.label} />
    <div className={classes.container}>
      <div>
        <Typography variant="h1" className={classes.subtitle}>
          Jahon iqtisodiyoti xalqaro toifadagi mutaxasislarga muhtoj. Har qanday
          sohada yetuk mutaxasis bo’lishni istagan har bir insondan ingliz
          tilini mukammal egallagan bo’lishi talab etiladi.
        </Typography>
        <Typography variant="h1" className={classes.subtitle}>
          Edvi.uz jamoasi esa ingliz tilini o’rganishingiz va o’z sohangizda
          xalqaro miqyosdagi mutaxasis bo’lishingizda sizga yordam berishni
          maqsad qilib qo’ydi. Biz hohlaymizki, xalqimiz orasida ingliz tilida
          suhbatlashuvchilar ko’paysin. Biz esa bu borada sizga yordam berishga
          harakat qilamiz!
        </Typography>
      </div>
      <img
        className={classes.img}
        src="/images/portrait-student-girl.png"
        alt="girl"
      />
    </div>
  </div>
));

const Benefits = withStyles({
  root: {
    minHeight: 596,
    backgroundColor: "white",
    paddingLeft: 135,
    paddingRight: 135,
  },
  label: {
    paddingTop: 70,
  },
  container: {
    marginTop: 35,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& > *": {
      marginBottom: 20,
    },
    paddingBottom: 40,
  },
  "@media (max-width: 600px)": {
    root: {
      display: "flex",
      flexDirection: "column",
      // alignItems: 'center',
      paddingLeft: 22,
      paddingRight: 0,
    },
    container: {
      paddingLeft: 21,
    },
  },
})(({ classes }) => (
  <div className={classes.root}>
    <UnderlinedLabel
      className={classes.label}
      text="Edvi platformasining ustunlikllari:
"
    />
    <div className={classes.container}>
      <BenefitsCard
        color="#178FC2"
        icon="/icons/mobile-phone.svg"
        title="Foydalanish uchun qulay mobil versiya"
        description="Agar siz ko'p harakatda bo'lsangiz yoki siz uchun mobil telefondan foydalangan holda bilim olish afzal bo'lsa, bizning mobil versiyamiz siz uchun juda qo'l keladi"
      />
      <BenefitsCard
        color="#178FC2"
        icon="/icons/expert.svg"
        title="Malakali ekspertdan bilim olish imkoniyati"
        description="Kursimiz TESOL sertifikatiga ega malakali va ingliz tilini o’rgatishda ko’p yillik tajribaga ega mutaxasis tomonidan ishlab chiqilgan."
      />
      <BenefitsCard
        color="#178FC2"
        icon="/icons/book (1).svg"
        title=" Oxford University Press o’quv dasturi asosida bilim olish"
        description="Oxford University Pressning “Navigate” kursi kommunikativ yondashuvga va xorijiy tillarni o’rganishdagi so’ngi ilmiy izlanishlarga asoslangan va hozirgi kunga kelib eng samarali o’quv qo’llanmalardan biri hisoblanadi."
      />
      <BenefitsCard
        color="#178FC2"
        icon="/icons/time.svg"
        title="Istalgan vaqtda va istalgan joydan o'rganish imkoniyati"
        description="Ingliz tilini istagan manzilingizdan va o’zingiz uchun qulay vaqtda o’rganish imkoniyatini taqdim etamiz. Bunda sizga internetga ulangan kompyuter yoki mobil telefon bo’lsa kifoya."
      />
      <BenefitsCard
        color="#178FC2"
        icon="/icons/progress.svg"
        title="So’zlashuv qobilyatingizni oshirishda Kurator yordami"
        description="Ko’pchilik onlayn kurslarni muammosi bu Speaking ya’ni so’zlashish qobiliyatini shakillantirishdagi muammolar. 
        Bizning kuratorlarimiz, aynan manashu ko’nikmani hosil qilishingizda va rivojlantirishingizda sizga yordam beradi!"
      />
      <BenefitsCard
        color="#178FC2"
        icon="/icons/analytics.svg"
        title="Zamonaviy va qulay platforma"
        description="Mukammal tuzilgan video darslar.
          Mavzuga oid amaliy mashqlar va baxolash tizimi.
          Maxsus kurator yordami.
          Haftalik Zoom uchrashuvlar.
          Umumiy natijangiz nazorati."
      />
    </div>
  </div>
));

const AboutEdvi = withStyles({
  root: {
    paddingTop: 50,
    paddingBottom: 50,
    backgroundColor: "#ffe9bb",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundImage: 'url(/images/circles2.svg)',
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: '80px 26px',
  },
  img: {
    marginLeft: 127,
  },
  about: {
    marginLeft: 115,
    marginRight: 85,
  },
  title: {
    fontSize: 35,
    lineHeight: "43px",
    fontWeight: 500,
    color: "#004766",
    marginBottom: 10,
  },
  description: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "23px",
    color: "#555",
    marginBottom: 48,
    textAlign: "justify",
  },

  "@media (max-width: 1130px)": {
    img: {
      marginLeft: 40,
    },
    about: {
      marginLeft: 50,
      marginRight: 60,
    },
  },

  "@media (max-width: 960px)": {
    img: {
      marginLeft: 10,
      maxWidth: "279px",
    },
    about: {
      marginLeft: 20,
      marginRight: 30,
    },
  },

  "@media (max-width: 770px)": {
    root: {
      flexWrap: "wrap",
    },
    // img: {
    //     display: 'none'
    // },
    title: {
      fontSize: "23px",
    },
    about: {
      textAlign: "center",
      maxWidth: 500,
      margin: "0 auto",
    },
  },

  "@media (max-width: 580px)": {
    // img: {
    //     display: 'none'
    // },
    about: {
      marginLeft: 35,
      marginRight: 35,
    },
  },
})(({ classes }) => (
  <div className={classes.root}>
    <img
      className={classes.img}
      src="/images/happy-young-woman-holding-laptop-gray-wall 1 1.png"
      alt="man"
    />
    <div className={classes.about}>
      <Typography className={classes.title} component="h2">
        Biz haqimizda
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Edvi.uz jamoasi ingliz tilini o'rganishni boshlaganlar yoki
        rivojlantirmoqchi bo’lganlar uchun qulaylik yaratish maqsadida
        innovatsion onlayn platforma yaratishni niyat qilgan malakali
        mutaxasislardan iborat. Zamon talabidan kelib chiqib, til o’rganish
        uchun oson va qulay, ta’lim sohasidagi so’nggi metodikalarni va
        zamonaviy tehnologilayarni qo’llagan holda katta maqsadlarni ko’zlab,
        xalqimiz manfaati yo’lida ushbu platformaga asos soldik. Platformamiz
        minglab insonlarni ingliz tilini o’rganishidagi asosiy ko’makchisi
        bo’ladi degan umiddamiz.
      </Typography>
    </div>
  </div>
));

const SuccessStories = withStyles({
  root: {
    minHeight: 802,
    backgroundColor: "white",
    paddingLeft: 135,
    paddingRight: 110,
  },
  label: {
    paddingTop: 70,
  },
  container: {
    marginTop: 40,
    display: "flex",
    // flexWrap: 'wrap',
    overflowY: "auto",
    justifyContent: "flex-start",
    "& > *": {
      marginRight: 25,
      marginTop: 100,
    },
    "@media (max-width: 400px)": {
      marginTop: 10,
    },
  },
  bottom: {
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
    paddingBottom: 50,
  },
  "@media (max-width: 600px)": {
    root: {
      minHeight: 710,
      paddingLeft: 35,
      paddingRight: 0,
    },
    // container: {
    //     '& > *': {
    //         marginRight: 0,
    //     }
    // }
  },
})(({ classes }) => {
  const feedbacks = [
    {
      id: 1,
      fullName: "Юлия Давидян      ",
      username: "@davidyan_julia",
      profilePhoto: "/images/julia3.jpg",
      socialIcon: "/icons/instagram.svg",
      feedback:
        "“Учителей много, кто-то компетентен, кто-то нет. Но Mr.Farruh, даёт понять именно любовь к языку.”",
    },
    {
      id: 2,
      fullName: "Abdulaziz Sharifxadjaev      ",
      username: "@sharifxadzaev",
      profilePhoto: "/images/abdulaziz.jpg",
      socialIcon: "/icons/instagram.svg",
      feedback:
        "“Noodatiy usul, ajoyib o'qituvchi va muvaffaqiyatga erishgan minnatdor talabalar! Hammaga tavsiya qilaman!”",
    },
    {
      id: 3,
      fullName: "Nozim Buranov      ",
      username: "@nozim.buranov.9",
      profilePhoto: "/images/Nozim.jpg",
      socialIcon: "/icons/facebook-filled.svg",
      feedback:
        "“Ajoyib pedagog Mr.Faruh dan o'rgangan bilimlarimni xalqaro kompaniyadagi ish faoliyatimda qo`llamoqdaman”",
    },
  ];
  return (
    <div className={classes.root}>
      <UnderlinedLabel className={classes.label} text="Talabalarimiz fikri" />
      <div className={classes.container}>
        {feedbacks.map((feedback) => (
          <FeedbackCard key={feedback.id} feedback={feedback} />
        ))}
      </div>
      {/* <div className={classes.bottom}>
                <CustomOutlinedButton>
                    More stories
                </CustomOutlinedButton>
            </div> */}
    </div>
  );
});

const OurExpert = withStyles({
  root: {
    // height: 440,
    backgroundColor:
      "linear-gradient(39.35deg, #ffffff -4.47%, #c68706 98.23%)",
    // backgroundImage: "url(/images/circles3.svg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100% 0x",
    display: "flex",
    // overflowY: 'hidden',
    justifyContent: "space-between",
    paddingLeft: 100,
    "@media (max-width: 600px)": {
      paddingLeft: 35,
      paddingRight: 10,
      flexWrap: "wrap",
    },
  },
  expertInfo: {
    // minWidth: 700,
    paddingTop: 60,
  },
  expertDetails: {
    display: "flex",
    flexWrap: "wrap",
    // gridTemplateColumns: '1fr 1fr',
    // gap: 30,
  },
  expertPhoto: {
    height: 464,
    width: "auto",
    marginRight: 42,
    marginTop: 15,
  },
  title: {
    fontSize: 35,
    lineHeight: "43px",
    fontWeight: 500,
    color: "#004766",
  },
  expertName: {
    fontSize: 23,
    lineHeight: "33px",
    textDecoration: "underlined",
    color: "black",
    borderBottom: "2px solid black",
    display: "inline-block",
    marginTop: 50,
    marginBottom: 14,
  },

  "@media (max-width: 400px)": {
    root: {
      paddingLeft: 22,
    },
    expertPhoto: {
      height: 385,
      width: 299,
    },
  },
})(({ classes }) => (
  <div className={classes.root}>
    <div className={classes.expertInfo}>
      <UnderlinedLabel text="Bizning ekspert:" />
      <Typography className={classes.expertName}>Farukh Korchiyev</Typography>

      <div className={classes.expertDetails}>
        <Typography className={classes.description}>
          • Edvi.uz platformasi muallifi va asoschilaridan biri.
          <br />
          • Xalqaro TESOL / TEFL sertifikati sohibi. <br />
          • 10 yildan ortiq tajribaga ega ingliz tili o`qituvchisi. Hozirga
          kelib 1000 dan ortiq shogirdlar yetishtirgan. <br />• Ko’plab
          shogirdlari chet el oliygohlarida (AQSH, Avstraliya, Germaniya,
          Polsha, Latviya, Rossiya) tahsil oladi va istiqomat qiladi. <br />
          • Ingliz tilini o’rganish va rivolantirish bo’yicha ko’plab loyihalar
          muallifi va spikeri. <br />• Xorijiy IT kompaniyasida direktor
          o’rinbosari lavozimida bir necha yil faoliyat yuritgan.
        </Typography>
      </div>
    </div>
    <img
      className={classes.expertPhoto}
      src="/images/our_expert.png"
      alt="teacher"
    />
  </div>
));

const EighthRow = withStyles({
  root: {
    backgroundColor: "white",
    paddingBottom: 50,
    paddingLeft: 135,
    paddingRight: 135,
  },
  // label: {
  //   paddingTop: 70,
  // },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    // marginTop: 35,
    "& > *": {
      marginBottom: 25,
    },
  },

  row: {
    display: "flex",
    minWidth: 328,
    maxWidth: 400,
    maxHeight: 300,
    minHeight: 100,
    flexDirection: "column",
  },
  videoPlayer: {
    width: "100%",
    height: "100%",
    marginRight: 20,
    marginBottom: 20,
    borderRadius: 10,
    border: "2px solid #fff",
  },
  "@media (max-width: 600px)": {
    root: {
      paddingLeft: 35,
      paddingRight: 35,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    container: {
      flexDirection: "column",
    },
  },
})(({ classes }) => (
  <div className={classes.root}>
    <div className={classes.container}>
      {/* <ContactsCard
        iconSrc="/icons/location.svg"
        title="Manzil:"
        subtitle="Amir Temur ko'chasi, 107B"
      />
      <ContactsCard
        iconSrc="/icons/phone.svg"
        title="Telefon:"
        subtitle="+998 99 446 80 28"
      />
      <ContactsCard
        iconSrc="/icons/email.svg"
        title="Telegram :"
        subtitle="+998 99 446 80 28"
      /> */}
      <div>
        <div
          style={{
            fontSize: 25,
            backgroundColor: "#80c9f0",
            padding: 10,
            borderRadius: 10,
            alignContent: "center",
            marginBottom: 10,
            cursor: "pointer",
          }}
        >
          <a style={{ textDecoration: "none" }} href="https://t.me/volinkin_m">
            Telegram <FaTelegram />
          </a>
        </div>
        <div
          style={{
            fontSize: 25,
            backgroundColor: "#80c9f0",
            padding: 10,
            borderRadius: 10,
            alignContent: "center",
            cursor: "pointer",
          }}
        >
          <a
            style={{ textDecoration: "none" }}
            href="https://www.instagram.com/volinkin_m/"
          >
            Instagram <FaInstagram />
          </a>
        </div>
      </div>
      <div className={classes.row}>
        <Typography variant="h5" style={{}}>
          Arnold Classicga tayyorlov trenirovkasi
        </Typography>
        <VideoPlayer
          src="https://volinkin.uz/uploads/videos/8df6c2ced47692b165aac4dec3faaefd.mp4"
          className={classes.videoPlayer}
          controls
        />
      </div>
    </div>
  </div>
));

const PricingRow = withStyles({
  root: {
    background: "linear-gradient(39.35deg, #ffffff -4.47%, #1752e0 98.23%)",
    paddingBottom: 50,
    paddingTop: 50,
    // paddingRight: 135,
  },
  title: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    // marginTop: 35,
    "& > *": {
      // marginBottom: 25,
    },
  },
  "@media (max-width: 600px)": {
    root: {
      paddingLeft: 35,
      paddingRight: 35,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    container: {
      flexDirection: "column",
    },
  },
})(({ classes }) => (
  <div className={classes.root}>
    <div className={classes.title}>
      <Typography align="center" variant="h3" style={{ color: "#fff" }}>
        Tariflar
      </Typography>
      <Typography></Typography>
    </div>
    <div className={classes.container}>
      <PricingCard1
        // iconSrc="/icons/location.svg"
        title="STANDART"
        subtitle="Amir Temur ko'chasi, 107B"
      />
      <PricingCard2
        iconSrc="/icons/phone.svg"
        title="Telefon:"
        subtitle="+998 99 446 80 28"
      />
    </div>
  </div>
));

const useStyles = makeStyles({
  row: {
    backgroundColor: "white",
    paddingLeft: 135,
    paddingBottom: 100,
  },
  rowLabel: {
    paddingTop: 70,
    marginBottom: 35,
  },

  "@media (max-width: 600px)": {
    row: {
      paddingLeft: 35,
    },
  },
});

const MainPage = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <FirstRow />
      {/* <FreeVideos /> */}
      <div className={classes.row}>
        <UnderlinedLabel className={classes.rowLabel} text="Mashg`ulotlar" />
        <CoursesList />
      </div>
      
      {/* <ThirdRow /> */}
      {/* <Benefits /> */}
      {/* <AboutEdvi /> */}
      {/* <SuccessStories feedbacks={[]} /> */}
      {/* <OurExpert /> */}
      <EighthRow />
      {/* <PricingRow /> */}
    </div>
  );
};
export default MainPage;
