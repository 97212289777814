import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  selectUserFullname,
  selectUserProfile,
} from "../redux/userStateSlice";
import { showSignIn, showSignUp } from "../redux/appStateSlice";

import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import ListItem from "@material-ui/core/ListItem";
import { useHistory } from "react-router";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    // alignItems: 'center',
    borderBottom: "1px solid #DCDCDC",
    transition: "all .2s ease",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "4px",
    marginTop: "7px",
    width: "100%",
    paddingRight: "49px",
    "@media (max-width: 600px)": {
      marginLeft: "15px",
      paddingRight: "15px",
    },
  },
  navbar: {
    width: "100%",
    maxWidth: "100%",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    transition: "all .5s ease",
    zIndex: 1000,
  },
  scrolled: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    // backgroundColor: '#eee'
  },
  searchRoot: {
    width: "100%",
    margin: "0px 20px",
    // '@media (max-width: 700px)': {
    //     display: 'none'
    // }
  },
  searchRoot2: {
    width: "100%",
    padding: "0 12px",
    // '@media (min-width: 700px)': {
    //     display: 'none'
    // }
  },
  searchOutline: {
    margin: "10px 0px",
    borderRadius: 20,
  },
  rightItems: {
    display: "flex",
    marginRight: 60,
    "& > * ": {
      marginLeft: "45px",
    },
  },
  grow: {
    flex: 1,
  },
  logoName: {
    fontSize: 20,
    fontWeight: "normal",
    marginLeft: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: "normal",
    marginRight: 10,
  },
  profile: {
    marginRight: 20,
  },
  logo: {
    width: 60,
    height: 60,
  },
  "@media (max-width: 400px)": {
    rightItems: {
      "& > * ": {
        marginLeft: "0px",
        marginRight: "0px",
      },
      marginRight: 0,
    },
  },
  // disableTextTransform: {
  //   textTransform: "none",
  // },
  blackColor: {
    color: "black",
  },
  signInButtonRoot: {
    borderColor: theme.palette.primary.main,
    borderRadius: 8,
    marginRight: 10,
    color: "#000",
    backgroundColor: "#fff",
  },
  rootMenu: {
    display: "flex",
    paddingLeft: 10,
    paddingTop: 10,
  },
  listItemRoot: {
    paddingTop: 1,
    paddingLeft: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  menuItemRoot: {
    paddingLeft: 2,
    paddingTop: 0,
    "@media (max-width:600px)": {
      minHeight: 0,
    },
  },
}));

const ProfileMenu = (props) => {
  const classes = useStyles();
  const { UserProfile } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [showLogoutPromt, setShowLogoutPromt] = useState(false);
  const dispatch = useDispatch();

  const handleMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setShowLogoutPromt(true);
  };

  const handleLogout2 = () => {
    dispatch(logout());
  };

  console.log("edvi UserProfile", UserProfile);

  return (
    <>
      <IconButton
        style={{
          backgroundColor: "#1752e0",
          color: "#fff",
          height: 40,
          width: 40,
          fontSize: 15,
          paddingTop: 10,
          border: '1px solid'
        }}
        onClick={handleMenuClick}
      >
        {UserProfile.name[0]}
      </IconButton>
      <Menu
        id="appbar-profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{ list: classes.rootMenu }}
      >
        <IconButton
          style={{
            backgroundColor: "#5EB6DC",
            color: "#fff",
            height: 40,
            width: 40,
            fontSize: 15,
          }}
          onClick={handleMenuClick}
        >
          {UserProfile.name[0]}
        </IconButton>
        <div
          style={{ display: "flex", flexDirection: "column", paddingLeft: 10 }}
        >
          <ListItem classes={{ root: classes.listItemRoot }}>
            {UserProfile.name}
            <div style={{ fontSize: 13, color: "grey" }}>
              +{UserProfile.phone_number}
            </div>
          </ListItem>

          <div style={{ display: "flex" }}>
            <ExitToAppIcon />
            {/* <img style={{height: 20, width:20 }} src="/images/logout.png" alt="logo" /> */}
            <MenuItem
              classes={{ root: classes.menuItemRoot }}
              onClick={handleLogout}
            >
              Chiqish
            </MenuItem>
          </div>
        </div>
      </Menu>
      <Dialog open={showLogoutPromt}>
        <DialogContent>
          <DialogContentText>Aniq chiqmoqchimisiz?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleLogout2}>
            Ha
          </Button>
          <Button color="primary" onClick={() => setShowLogoutPromt(false)}>
            Yo`q
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AppBar = ({ title }) => {
  const [scrolled, setScrolled] = React.useState(false);

  const classes = useStyles();
  const history = useHistory();
  const handleSignIn = () => {
    dispatch(showSignIn());
  };
  const handleSignUp = () => {
    dispatch(showSignUp());
  };
  const dispatch = useDispatch();
  const userFullName = useSelector(selectUserFullname);
  const UserProfile = useSelector(selectUserProfile);
  const handleHomeClick = (e) => {
    e.preventDefault();
    history.push("/");
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  console.log("scrolled", scrolled);
  return (
    <div
      className={
        scrolled ? classes.navbar + " " + classes.scrolled : classes.scrolled
      }
    >
      <div className={classes.row}>
        <Hidden xsDown>
          <div
            className={classes.grow}
            style={{
              maxWidth: "50px",
            }}
          />
        </Hidden>

        <a href="/" onClick={handleHomeClick}>
          <img className={classes.logo} src="/images/1.png" alt="logo" />
        </a>
        {/* <Typography variant="h1" className={classes.logoName}>
            Volinkin.uz
          </Typography> */}
        <Hidden xsDown>
          <div className={classes.grow} />
        </Hidden>

        {/* {title && (
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
        )} */}
        {/* {!title && (
          <Hidden xsDown>
            <TextField
              variant="outlined"
              InputProps={{
                endAdornment: <SearchIcon />,
                classes: {
                  root: classes.searchRoot,
                  notchedOutline: classes.searchOutline,
                },
              }}
            />
          </Hidden>
        )} */}
        {/* <div className={classes.rightItems}>
          <Button
            classes={{
              label: clsx(classes.disableTextTransform, classes.blackColor),
            }}
          >
            Kurslar
          </Button>
          <Button
            classes={{
              label: clsx(classes.disableTextTransform, classes.blackColor),
            }}
          >
            Biz haqimizda
          </Button>
          
        </div> */}
        <Hidden xsDown>
          {!userFullName && (
            <Button
              classes={{
                root: classes.signInButtonRoot,
                // label: clsx(
                //   classes.disableTextTransform,
                //   classes.signInButtonText
                // ),
              }}
              variant="contained"
              color="primary"
              onClick={handleSignUp}
            >
              Ro`yhatdan o`tish
            </Button>
          )}
        </Hidden>
        {!userFullName && (
          <Button
            classes={{
              root: classes.signInButtonRoot,
              label: clsx(
                classes.disableTextTransform,
                classes.signInButtonText
              ),
            }}
            variant="contained"
            color="primary"
            onClick={handleSignIn}
          >
            Kirish
          </Button>
        )}

        {userFullName && <ProfileMenu UserProfile={UserProfile} />}
      </div>
      {/* {
        <Hidden smUp>
          <TextField
            classes={{
              root: classes.searchRoot2,
            }}
            variant="outlined"
            InputProps={{
              endAdornment: <SearchIcon />,
              classes: {
                root: classes.searchRoot2,
                notchedOutline: classes.searchOutline,
              },
            }}
          />
        </Hidden>
      } */}
    </div>
  );
};

export default AppBar;
