import React, {useState} from 'react';
import {asyncSignIn, selectUserStateStatus, selectUserStateError, setProfile} from '../../redux/userStateSlice'
import {showSignUp} from '../../redux/appStateSlice'
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux'
import {ServerError  } from "../../utils/Error";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CloseIcon from '@material-ui/icons/Close';
import { signIn } from '../../APIs/auth';
import { setUserState } from '../../utils/userStateCache';
import axios from 'axios';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://volinkin.uz/">
        Volinkin.uz
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  main:{
    display: "flex",
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icons:{
    marginTop: 16,
    cursor: 'pointer',
    '&:hover':{
      backgroundColor: '#0a6186',
      color: 'white'
    }
  },
}));

const SignIn = (props) => {
  const {close} = props;
  const classes = useStyles();
  const dispatch = useDispatch()
  const authStatus = useSelector(selectUserStateStatus)
  const authError = useSelector(selectUserStateError)

  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('+')
  
  const pending = authStatus === 'pending'
  let invalidLoginOrPassword = false
  let validationError = false
  let errorText = null
  if (authError) {
    if (authError.name === 'ServerError') {
      switch (authError.code) {
        case ServerError.Code.InvalidLoginOrPassword:
          invalidLoginOrPassword = true
          errorText = 'Invalid login or password'
          break
        case ServerError.Code.ValidationError:
          validationError = true
          errorText = 'Please fill the fields with valid values'
          break
        default:
          errorText=authError.message
          break
      }
    }
    else {
      errorText=authError.message
    }
  }

  const onChangePhoneNumber = (e) => {
  
    /**
     * @type {string}
     */
    let value = e.target.value

    if (!value.startsWith('+')) {
      value = `+${value} `
    }
    setPhone(value)
    // const re = /\+998 \d{0,9}/g;
    // let matched = value.match(re)
    // if (matched && matched.length > 0) {
    //   setPhone(matched[0])
    // }
    
}

  const handleSignUp = e => {
    e.preventDefault()
    dispatch(showSignUp())
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    await axios.post('https://volinkin.uz/api/user/login1', {
      phone_number: phone.replace(' ', '')
    })
    .then(res => {
      console.log('handleSubmit', res.data.data);
      close();
      dispatch(setProfile(res.data.data));
      setUserState(res.data.data._id, res.data.data);
    })
    .catch(err => {
      console.error('err', err);
    })
    // dispatch(asyncSignIn(email, password))
  }

  return (
    <Container className={classes.main}  component="main" maxWidth="xs">
      <CssBaseline />
        <CloseIcon onClick={close} className={classes.icons}/>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Kirish
        </Typography>
        {errorText && (
          <Typography variant="subtitle1" color="secondary">
            {errorText}
          </Typography>
        )}
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <Typography>Telefon raqamingiz</Typography>
              <TextField
                
                variant="outlined"
                required
                fullWidth
                id="pusername"
                name="pusername"
                value={phone}
                onChange={onChangePhoneNumber}
                disabled={pending}
                type="tel"
                inputProps={{
                  pattern: "\+998 \d{2} \d{3}-\d{4}"
                }}
              />
            </Grid>
         
          <Grid container>
            <Grid item xs={12} style={{textAlign:"center", marginTop: 10}}>
              <Link href="#" variant="body2">
              Nomeringizni unutdingizmi?
              </Link>
            </Grid>
            <Box style={{height:"46px"}}></Box>
            <Grid xs={12} item style={{textAlign:"center"}}>
              <Typography >
              Akkauntingiz hali yo'qmi?
              </Typography>
              <Button variant="contained" onClick={handleSignUp}>
                {"Ro'yxatdan o'tish"}
              </Button>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={pending}
          >
            Kirish
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default SignIn