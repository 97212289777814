import { NetworkError, ServerError } from "./Error";

export default function parseResponse(response) {
    console.log('parseResponse ', response);
    // if (response.status !== 200) {
    //     throw new NetworkError(response.status, response.statusText)
    // }
    // if (!response.data.success) {
    //     throw new ServerError(response.data.error.code, response.data.error.description)
    // }
    return response.data.result
}