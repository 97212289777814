import React, { useEffect, useRef } from 'react'
import { Route, Switch, useHistory, useLocation} from 'react-router-dom'

import AuthForm from './Components/Auth/AuthForm'
import AppBar from './Components/AppBar'
import MainPage from './Components/MainPage/MainPage'
import CourseOverview from './Components/CourseOverview/CourseOverview'
import Course from './Components/Course/Course'
import Footer from './Components/Footer'
import BuyCourse from './Components/BuyCourse/BuyCourse'
import ReactGA from 'react-ga';

import { useDispatch, useSelector } from 'react-redux'
import { asyncLoadUserState, selectUserProfile } from './redux/userStateSlice'
import { selectLastRoute, setLastRoute } from './redux/appStateSlice'
import NutritionPage from './Components/Nutrition/NutritionPage'

ReactGA.initialize('G-M03LFQGQ8E');

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

function App() {
  const history = useHistory()
  const dispatch = useDispatch()
  const profile = useSelector(selectUserProfile);

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  useEffect(() => {
    dispatch(setLastRoute(location.pathname))
  }, [location])

  useEffect(() => {
    dispatch(asyncLoadUserState())
    // if (lastRoute) {
    //   history.push(lastRoute)
    // }
  }, [])

  return (
      <div style={{ width: '100%',  margin: '0 auto'}}>
        <AppBar title={profile && `${profile.name}`}/>
          <Switch>
            <Route path="/nutrition/:category">
              <NutritionPage/>
            </Route>
            <Route path="/course/:courseID/overview">
              <CourseOverview/>
            </Route>
            <Route path="/course/:courseID">
              <Course/>
            </Route>
            <Route path="/">
              <MainPage/>
            </Route>
          </Switch>
        <Footer/>
        <AuthForm/>
        <BuyCourse/>
      </div>  
  );
}

export default App;
