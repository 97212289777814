import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { selectCourseById, selectUnitById, selectLessonById } from "../../redux/entitiesSlice";
import { openBuyCoursePage } from '../../redux/appStateSlice';
import { selectUserProgress } from '../../redux/userStateSlice'
import getStarsCount from "../../utils/getStarsForProgress";

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Rating from '@material-ui/lab/Rating'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Hidden  from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
    root: {
        borderLeft: '1px solid #DCDCDC',
        '@media (max-width:600px)':{
            marginTop: 19
        }
    },
    main:{
        position: 'relative'
      },
    header: {
        height: 72,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
    },
    caption: {
        fontSize: 22,
        fontWeight: 'bold',
        lineHeight: '27px',
        color: '#4B4B49',
    },
    accordion: {
        backgroundColor: '#EDEDED',
    },
    accordionSummary: {
        display: 'flex',
        flexDirection: 'column',
        
    },
    accordionSummaryTitle: {
        fontSize: 15,
        fontWeight: 600,
        lineHeight: '19px',
        color: '#393939',
    },
    accordionSummarySubtitle: {
        fontSize: 11,
        fontWeight: 500,
        lineHeight: '14px',
        color: '#494949',
    },
    accordionDetails: {
        backgroundColor: 'white',
        padding: 0,
    },
    accordionDetailsList: {
        width: '100%',
        padding: 0,
    },
    accordionDetailsItem: {
        display: 'flex',
        alignItems: 'center',
        gap: 0,
        borderBottom: '1px solid #DCDCDC',
    },
    listItemSelected: {
        backgroundColor: 'aliceblue',
    },
    accordionDetailsIcon: {
        minWidth: 0,
        marginRight: 11,
    },
    accordionDetailsTitle: {
        fontSize: 13,
        fontWeight: 500,
        lineHeight: '16px',
        color: "#393939",
    },
    accordionDetailsSubtitle: {
        fontSize: 11,
        fontWeight: 400,
        lineHeight: '14px',
        color: '#',
    },
    playIcon: {
        fontSize: 12,
        color: '#6E6E6E'
    },
    checkDefaultColor: {
        color: '#A8A8A8',
    },
    checkPassedColor: {
        color: theme.palette.primary.main
    },
    unitProgress: {
        marginRight: 8,
    },
    buyModal:{
        position: 'absolute',
        top: 270,
        zIndex:1000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#FFFFFF',
        opacity: '82%',
        boxShadow: '0px 2px 10px 5px rgba(34, 60, 80, 0.2)',
        padding: '26px 10px'
    },
}))

const LessonItem = ({unitPosition, lessonID, selected, onClick}) => {
    const classes = useStyles()
    const lesson = useSelector(state => selectLessonById(state, lessonID))
    const progress = useSelector(state => selectUserProgress(state, lessonID))
    const starsCount = getStarsCount(progress)

    return (
        <ListItem 
            className={clsx(classes.accordionDetailsItem, selected && classes.listItemSelected)}
            button onClick={() => onClick(lesson)}
        >
            <ListItemIcon className={classes.accordionDetailsIcon}>
                <CheckCircleIcon size="small" className={starsCount > 0 ? classes.checkPassedColor : classes.checkDefaultColor}/>
            </ListItemIcon>
            <div>
                <Typography className={classes.accordionDetailsTitle} variant="h6">
                    {`${unitPosition !== 0 ? unitPosition + '.' : ''}${lesson.position}. ${lesson.title}`}
                </Typography>
                <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                    <PlayCircleOutlineIcon size="small" className={classes.playIcon}/>
                    <Typography className={classes.accordionDetailsSubtitle}>2 videos / 5 min</Typography>
                </div>
                <Rating readOnly value={starsCount} max={3} size="small" name={`rating-for-lesson-${lessonID}`}/>
            </div>
        </ListItem>
    )
}

const Unit = ({unitID, selectedLessonID, onLessonSelected, onUnitUnavailble}) => {
    const classes = useStyles()
    const unit = useSelector(state => selectUnitById(state, unitID))
    const lessonProgress = useSelector(state => unit.lessons.map(id => selectUserProgress(state, id)))
    
    let totalLessons = unit.lessons.length
    let lessonsPassed = 0
    let starsCount = 0
    lessonProgress.forEach(progress => {
        if (!isNaN(progress)) {
            const count = getStarsCount(progress)
            if (count > 0) {
                starsCount += count
                lessonsPassed++
            }
        }
    })
    if (totalLessons !== lessonsPassed) {
        starsCount = 0
    }
    else {
        starsCount /= totalLessons
    }

    const handleSelectLesson = lessonID => {
        if (!unit.available) {
            onUnitUnavailble(unitID)
        }
        else if (onLessonSelected) {
            onLessonSelected(lessonID)
        }
    }

    const unitTitle = unit.position > 0 ? `Unit: ${unit.position}. ${unit.title}` : `Unit: ${unit.title}`
    const defaultExpanded = unit.lessons.includes(selectedLessonID)
    console.log('edvi unit', defaultExpanded)
    return (
        <Accordion className={classes.accordion} style={{margin: 0}} defaultExpanded={defaultExpanded}>
            <AccordionSummary 
                expandIcon={<ExpandMoreIcon/>} 
                classes={{
                    content: classes.accordionSummary,
                }}
            >
                <Typography variant="h1" className={classes.accordionSummaryTitle}>
                    {unitTitle}
                </Typography>
                <Typography variant="subtitle1" className={classes.accordionSummarySubtitle}>
                    <span className={classes.unitProgress}>{`${lessonsPassed}/${totalLessons}`}</span> 1h 60min
                </Typography>
                <Rating value={starsCount} max={3} size="small" readOnly/>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <List className={classes.accordionDetailsList}>
                    {unit.lessons.map(lessonID => (
                        <LessonItem 
                            key={lessonID} 
                            unitID={unit.id} 
                            unitPosition={unit.position} 
                            lessonID={lessonID}
                            selected={selectedLessonID === lessonID}
                            onClick={handleSelectLesson}
                        />
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    )
}

const UnitsList = ({courseID, className, selectedLessonID, onLessonSelected, scrollTop}) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const course = useSelector(state => selectCourseById(state, courseID))
    const units = course.units ? course.units : []
    const [limitedLessonSelected, setLimitedLessonSelected] = useState(false)
    const [showUnitNotAvailable, setShowUnitNotAvailable] = useState(false)
    const handleLessonSelected = lesson => {
        scrollTop()
        if (lesson.is_free === false && course.has_bought === false) {
            setLimitedLessonSelected(true)
        }
        else {
            onLessonSelected(lesson.id)
        }
    }

    const handleUnavailableUnitSelected = unitID => {
        setShowUnitNotAvailable(true)
    }

    const handleCloseDialog = () => {
        setLimitedLessonSelected(false)
        setShowUnitNotAvailable(false)
    }

    const handleBuyCourse = () => {
        setLimitedLessonSelected(false)
        dispatch(openBuyCoursePage(courseID))
    }

    const handleBuy = () => {
        dispatch(openBuyCoursePage(courseID))
      }

    let dialogContent = null
    if (limitedLessonSelected) {
        dialogContent = (
            <>
            <DialogTitle>Siz sinov versiyasidasiz</DialogTitle>
            <DialogContent>
                <DialogContentText>
                Video darslarni ko'rishni davom ettirish uchun kursni sotib oling
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={handleCloseDialog}>Yo`q, rahmat</Button>
                <Button variant="contained" color="primary" onClick={handleBuyCourse}>Sotib olish</Button>
            </DialogActions>
            </>
        )
    }
    else if (showUnitNotAvailable) {
        dialogContent = (
            <>
            <DialogTitle>Ruxsat yo'q!</DialogTitle>
            <DialogContent>
                <DialogContentText>
                Ushbu Unit videolarini ko'rish uchun, avvalgi Unitlarni tugating.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={handleCloseDialog}>Got, it!</Button>
            </DialogActions>
            </>
        )
    }
   
    return (
        <div className={clsx(classes.root)}>
            <Hidden smDown>
                <header className={classes.header}>
                    <Typography className={classes.caption}>
                    Kurs rejasi
                    </Typography>
                </header>
            </Hidden>
            <main className={classes.main}>
                {units.map(unitID => (
                        <Unit 
                            key={unitID} 
                            unitID={unitID} 
                            selectedLessonID={selectedLessonID} 
                            onLessonSelected={handleLessonSelected}
                            onUnitUnavailble={handleUnavailableUnitSelected}
                        />
                ))}
            </main>
            <Dialog open={limitedLessonSelected || showUnitNotAvailable}>
                {dialogContent}
            </Dialog>
        </div>
    )
}

UnitsList.propTypes = {
    courseID: PropTypes.number.isRequired,
    selectedLessonID: PropTypes.number,
    onLessonSelected: PropTypes.func.isRequired,
    className: PropTypes.string,
}

export default UnitsList