import React, {useEffect, useState} from 'react'
import { makeStyles } from "@material-ui/core/styles";
import {  useDispatch, useSelector} from "react-redux" 
import CourseCard from './CourseCard'
import Button from '@material-ui/core/Button'
import CircularProgress from "@material-ui/core/CircularProgress";
import img1 from '../../assets/images1/insta.PNG'
import img2 from '../../assets/images1/welcome.png'
import img3 from '../../assets/images1/sportpit.png'

import { fetchCourses, selectAllCoursesIds, selectLoadCoursesStatus } from "../../redux/entitiesSlice";
import { getCourses } from '../../APIs/courses';
import { Typography } from '@material-ui/core';
import CourseNutritionCard from './CourseNutritionCard';

const trenka = [
    {
        title: 'Yangi boshlaganlar uchun', 
        author: 'Volinkin',
        category: 'begginer',
        tag: '',
        image: img1,
        borderColor: "#006EC1"
    },
    {
        title: 'Tajribalilar uchun', 
        author: 'Volinkin',
        category: 'middle', 
        tag: '',
        image: img1,
        borderColor: "#31283D"

    },
    {
        title: 'Super setlar', 
        author: 'Volinkin',
        category: 'senior',
        tag: '',
        image: img1,
        borderColor: '#272919'
    },
    
]

const pitaniya = [
    {
        title: 'Massa', 
        author: 'Volinkin',
        category: 'pit-massa',
        tag: '',
        image: "/images/sportpit.png"
    },
    {
        title: 'Sushka', 
        author: 'Volinkin',
        category: 'pit-sushka',
        tag: '',
        image: "/images/sportpit.png"
    },
   
]

const kurs = [
    {
        title: 'Massa', 
        author: 'Volinkin',
        category: 'kurs-massa',
        tag: '',
        image: "/images/nutpit.png"
    },
    {
        title: 'Sushka', 
        author: 'Volinkin',
        category: 'kurs-sushka',
        tag: '',
        image: "/images/nutpit.png"
    }
    
]

const pkt = [
    {
        title: 'PKT', 
        author: 'Volinkin',
        category: 'pkt',
        tag: '',
        image: "/images/pkt.jpeg"
    }
]

const products = [
    {
        title: 'Batonchik', 
        author: 'Volinkin',
        category: 'product',
        tag: '',
        image: "/images/pkt.jpeg"
    },
    {
        title: 'Mayka', 
        author: 'Volinkin',
        category: 'product',
        tag: '',
        image: "/images/pkt.jpeg"
    },
    {
        title: 'Fudbolka shortik', 
        author: 'Volinkin',
        category: 'product',
        tag: '',
        image: "/images/pkt.jpeg"
    }
]

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        // overflowY: 'auto',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
    showMoreButton: {
        width: 166,
        height: 40,
        borderRadius: 28,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main
    },
    showMoreLabel: {
        color: theme.palette.primary.main
    },
    footer: {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 60,
    },
    text: {
        fontFamily: "Montserrat, Arial, sans-serif",
        fontSize: 30,
        fontWeight: 600,
        lineHeight: "37px",
        letterSpacing: "0em",
        marginTop: 10,
        marginBottom: 10
      },
}))

const CoursesList = () => {
    const [data, setData] = useState([])


    const classes = useStyles()
    const courses = useSelector(selectAllCoursesIds)
    const status = useSelector(selectLoadCoursesStatus)
    const dispatch = useDispatch()
    useEffect(() => {
        if (status === 'idle' || status === 'rejected') {
            dispatch(fetchCourses())
        }
    }, [status])

    useEffect(() => {
        getCourses()
        .then(res => {
            setData(res)
        })
        .catch(err => {
            console.log('useeffect Courselist', err);
        })
        
    }, [])

    const needShowMoreButton = courses && courses.length < 3

    let component
    let component2
    let component3
    let component4
    let component5
    if (status === 'fulfilled') {
        component = trenka.map((item, index) => <CourseCard courseID={item._id} key={index} item={item}/>)
        component2 = pitaniya.map((item, index) => <CourseNutritionCard courseID={item._id} key={index} item={item}/>)
        component3 = kurs.map((item, index) => <CourseNutritionCard courseID={item._id} key={index} item={item}/>)
        component4 = pkt.map((item, index) => <CourseNutritionCard courseID={item._id} key={index} item={item}/>)
        component5 = products.map((item, index) => <CourseNutritionCard courseID={item._id} key={index} item={item}/>)
    }
    else {
        component = <CircularProgress size={100}/>
    }

    return (
        <>
            <div className={classes.container}>
                {component}
                {/* <CourseCard soon={true} key={2} courseID={2}/>
                <CourseCard soon={true} key={2} courseID={2}/> */}
            </div>
            <Typography variant="h2" className={classes.text}>Parxez</Typography>
            <div className={classes.container}>
                {component2}
                {/* <CourseCard soon={true} key={2} courseID={2}/>
                <CourseCard soon={true} key={2} courseID={2}/> */}
            </div>
            <Typography variant="h2" className={classes.text}>Kurs</Typography>
            <div className={classes.container}>
                {component3}
                {/* <CourseCard soon={true} key={2} courseID={2}/>
                <CourseCard soon={true} key={2} courseID={2}/> */}
            </div>
            <Typography variant="h2" className={classes.text}>PKT</Typography>
            <div className={classes.container}>
                {component4}
                {/* <CourseCard soon={true} key={2} courseID={2}/>
                <CourseCard soon={true} key={2} courseID={2}/> */}
            </div>
            {/* <Typography variant="h2" className={classes.text}>Maxsulotlarimiz</Typography> */}
            <div className={classes.container}>
                {/* {component5} */}
                {/* <CourseCard soon={true} key={2} courseID={2}/>
                <CourseCard soon={true} key={2} courseID={2}/> */}
            </div>
            {/* {needShowMoreButton && (
                <div className={classes.footer}>
                <Button
                    classes={{
                        root: classes.showMoreButton,
                        label: classes.showMoreLabel
                    }}
                >
                    Show more
                </Button>
            </div>
            )} */}
        </>
    )
}

export default CoursesList