import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { useDispatch, useSelector } from "react-redux";
import {
  asyncSignUp,
  selectUserStateError,
  selectUserStateStatus,
  setAuthError,
  setProfile,
} from "../../redux/userStateSlice";
import { showSignIn } from "../../redux/appStateSlice";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/styles";
import { signUp } from "../../APIs/auth";
import Congratulations from "./Congratulations";
import { setUserProfile } from "../../APIs/userState";
import { setUserState } from "../../utils/userStateCache";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import axios from "axios";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      padding: 0,
    },
  },
})(TextField);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://yulduzrajabova.uz">
        yulduzrajabova.uz
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    paddingBottom: "25px",
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icons: {
    marginTop: 16,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0a6186",
      color: "white",
    },
  },
  textField: {
    padding: 0,
  },
}));

const SignUp = ({ close }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+ ");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [localError, setLocalError] = useState(null);
  const [showCong, setShowCong] = useState(false);

  const authStatus = useSelector(selectUserStateStatus);
  const authError = useSelector(selectUserStateError);
  const pending = authStatus === "pending";
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const onChangePhoneNumber = (e) => {
    /**
     * @type {string}
     */
    let value = e.target.value;
    if (!value.startsWith("+")) {
      value = `+${value} `;
    }
    setPhone(value)

    // const re = /\+998 \d{0,9}/g;
    // let matched = value.match(re);
    // if (matched && matched.length > 0) {
    //   setPhone(matched[0]);
    // }
  };

  const handleCloseDialog = () => {
    setShowCong(false);
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    dispatch(showSignIn());
  };

  function detectOS() {
    const platform = navigator.platform.toLowerCase(),
        iosPlatforms = ['iphone', 'ipad', 'ipod', 'ipod touch'];

    if (platform.includes('mac')) return 'MacOS';
    if (iosPlatforms.includes(platform)) return 'iOS';
    if (platform.includes('win')) return 'Windows';
    if (/android/.test(navigator.userAgent.toLowerCase())) return 'Android';
    if (/linux/.test(platform)) return 'Linux';

    return 'unknown';
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
   if (phone.length === 0) {
      setLocalError("Phone number is required");
    } else if (firstName.length === 0) {
      setLocalError("First name is required");
    } else if (lastName.length === 0) {
      setLocalError("Last name is required");
    } else if (age.length === 0) {
      setLocalError("Age is required");
    } else {
      console.log("singup", age);
      let full_name = firstName + " " + lastName ;

      

      await axios.post('https://volinkin.uz/api/user/add', {
        name: full_name,
        phone_number: phone.replace(" ", ""),
        age: age,
        platform: detectOS()
      })
        .then((res) => {
          dispatch(setProfile(res.data.result));
          setUserState(res.data.result._id, res.data.result);
          setShowCong(true);
          console.log("res singup", res.data.result);
        })
        .catch((err) => {
          console.log('error sign up', err.response.data);
          setLocalError(err.response.data.message)
        });
    }
  };


  let errorText = null;
  if (authError) {
    errorText = authError.message;
  } else if (localError) {
    errorText = localError;
  }

  return (
    <Container className={classes.main} component="main" maxWidth="xs">
      <CssBaseline />
      <CloseIcon onClick={close} className={classes.icons} />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Ro'yxatdan o'tish
        </Typography>
        {errorText && (
          <Typography variant="subtitle1" color="secondary">
            {errorText}
          </Typography>
        )}
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>Ismingiz</Typography>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                inputProps={{
                  inp: classes.textField,
                }}
                fullWidth
                id="firstName"
                autoFocus
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                disabled={pending}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Familiyangiz</Typography>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                name="lastName"
                autoComplete="lname"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                disabled={pending}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Telefon raqamingiz</Typography>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="pusername"
                name="pusername"
                value={phone}
                onChange={onChangePhoneNumber}
                disabled={pending}
                type="tel"
                inputProps={{
                  pattern: "+ d{2} d{3}-d{4}",
                }}
              />
            </Grid>
          </Grid>
          <Box style={{marginTop: 10}} sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Yoshingiz</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value="18">18 yoshgacha</MenuItem>
              <MenuItem value="18-25">18-25 yosh</MenuItem>
              <MenuItem value="25-30">25-30 yosh</MenuItem>
              <MenuItem value="30-40">30-40 yosh</MenuItem>
            </Select>
          </FormControl>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={pending}
          >
            Ro`yxatdan o`tish
          </Button>
          <Grid container style={{ textAlign: "center", marginBottom: "10px" }}>
            <Grid xs={12} item>
              <Link href="#" variant="body2" onClick={handleSignIn}>
                Akkauntingiz bormi? Kirish ga bosing
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Dialog
        classes={{ paperScrollPaper: classes.rad }}
        open={showCong}
        onClose={handleCloseDialog}
      >
        <div>
          <Congratulations />
        </div>
      </Dialog>
    </Container>
  );
};

export default SignUp;
