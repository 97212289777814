import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material-UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";

// Icons
import PlaybackIcon from "../../Icons/Playback";
import ExercisesIcon from "../../Icons/Exercises";
import PracticeIcon from "../../Icons/Practise";
import LanguageIcon from "../../Icons/Language";
import LevelIcon from "../../Icons/Level";
import RecycleIcon from "../../Icons/Recycle";
import CertificateIcon from "../../Icons/Certificate";

import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { openBuyCoursePage } from "../../redux/appStateSlice";
import { selectCourseById } from "../../redux/entitiesSlice";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.15)",
    border: "1px solid #DCDCDC",
    borderRadius: 8,
    backgroundColor: "white",
    "@media (max-width: 600px)": {
      border: 0,
      boxShadow: "none",
    },
  },

  title: {
    fontSize: 20,
    lineHeight: "24px",
    fontWeight: 600,
    color: "#4B4B49",
    paddingTop: 15,
    paddingLeft: 42,
    paddingBottom: 12,
    "@media (max-width: 600px)": {
      paddingLeft: 0,
    },
  },
  list: {
    paddingRight: 1,
    paddingLeft: 40,
    "@media (max-width: 600px)": {
      paddingLeft: 0,
    },
  },
  listItemIcon: {
    minWidth: 0,
    paddingRight: 20,
  },
  listItemText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: "#4B4B49",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
    justifyContent: "center",
    alignItems: "center",
    "& *": {
      margin: 10,
    },
  },
  buyCourseButton: {
    minWidth: 200,
    textTransform: "none",
  },
  "@media (max-width: 960px)": {
    root: {
      width: "100%",
    },
    footer: {
      flexDirection: "row",
    },
    buyCourseButton: {
      minWidth: 157,
    },
  },
  "@media (max-width: 400px)": {
    buyCourseButton: {
      minWidth: 100,
    },
  },
});

const RightBlock = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const courseID = parseInt(params["courseID"]);
  console.log('rightblock params', params["courseID"]);
  const course = useSelector((state) => selectCourseById(state, courseID));
  const handleTryForFree = () => {
    history.push("/course/" + courseID);
  };

  const handleBuy = () => {
    dispatch(openBuyCoursePage(courseID));
  };

  let footer;
  if (course && course.has_bought) {
    footer = (
      <>
        <Button
          variant="outlined"
          color="primary"
          className={classes.buyCourseButton}
          onClick={handleTryForFree}
        >
          Davom etish
        </Button>
      </>
    );
  } else {
    footer = (
      <>
        <Button
          variant="contained"
          color="primary"
          className={classes.buyCourseButton}
          onClick={handleBuy}
        >
          Kurs sotib olish
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className={classes.buyCourseButton}
          onClick={handleTryForFree}
        >
          Bepul sinab ko'rish
        </Button>
      </>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        Kurs haqida ma'lumot:
      </Typography>
      <Hidden smDown>
        <Divider />
      </Hidden>
      <List className={classes.list}>
        {[
          {
            title: "15 soatlik video kurs",
            icon: <PlaybackIcon />,
          },
          {
            title: "100 dan ortiq amaliy mashqlar",
            icon: <ExercisesIcon />,
          },
          {
            title: "14 amaliy topshiriqlar.",
            icon: <PracticeIcon />,
          },
          {
            title: "Til: Ingliz tili",
            icon: <LanguageIcon />,
          },
          {
            title: `Daraja: Boshlang'ich`,
            icon: <LevelIcon />,
          },
          {
            title:
              "3 oy platformadan foydalanish huquqi",
            icon: <RecycleIcon />,
          },
          {
            title:
              "Kursni tamomlaganlik haqida sertifikat",
            icon: <CertificateIcon />,
          },
        ].map((item, index) => (
          <ListItem disableGutters key={index}>
            <ListItemIcon className={classes.listItemIcon}>
              {item.icon}
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={item.title}
            />
          </ListItem>
        ))}
      </List>
      <div className={classes.footer}>{footer}</div>
    </div>
  );
};

export default RightBlock;
