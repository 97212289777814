import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import * as api from '../APIs/auth'
import {getUserProfile, getUserProgress, setUserProfile, setUserProgress} from '../APIs/userState'
import * as cache from '../utils/userStateCache'

const _asyncSignIn = createAsyncThunk('userState/signIn', async (credentials) => {
    const token = await api.signIn(credentials)
    const profile = await getUserProfile(token)
    const progress = await getUserProgress(token)
    return {token, profile, progress}
}, {
    condition: (_, thunkAPI) => {
        const status = selectUserStateStatus(thunkAPI.getState())
        return status === 'idle' || status === 'rejected'
    }
})

const _asyncSignUp = createAsyncThunk('userState/signUp', async (credentials) => {
    const token = await api.signUp(credentials)
    const {password, ...profile} = credentials
    return {token, profile, progress: []}
}, {
    condition: (_, thunkAPI) => {
        const status = selectUserStateStatus(thunkAPI.getState())
        return status === 'idle' || status === 'rejected'
    }
})

const _asyncSaveUserProgress = createAsyncThunk('userState/saveUserProgress', async (userProgress, thunkAPI) => {
    return await setUserProgress(selectAccessToken(thunkAPI.getState()), userProgress)
}, {
    condition: (userProgress, thunkAPI) => {

        if (selectAccessToken(thunkAPI.getState())) {
            if (Array.isArray(userProgress) && userProgress.length === 1) {
                const {lesson_id, progress: newProgress} = userProgress[0]
                const currentProgress = selectUserProgress(thunkAPI.getState(), lesson_id)
                return !currentProgress || currentProgress < newProgress
            }
        }

        return false
    }
})

export const asyncLoadUserState = createAsyncThunk('userState/loadUserState', async (_, thunkAPI) => {
    const token = selectAccessToken(thunkAPI.getState())
    const profile = await getUserProfile(token)
    const progress = await getUserProgress(token)
    return {token, profile, progress}
}, {
    condition: (_, thunkAPI) => {
        if (selectAccessToken(thunkAPI.getState())) {
            const status = selectUserStateStatus(thunkAPI.getState())
            return status === 'idle' || status === 'rejected'
        }
        return false
    }
})

const slice = createSlice({
    name: 'userState',
    initialState: {
        token: cache.getCachedToken(),
        profile: cache.getCachedUserProfile(),
        progress: cache.getCachedUserProgres(),
        status: 'idle',
        error: null,
    },
    reducers: {
        setAuthError:(state, action) => {
            console.log('setAuthError', action);
            state.error = action.payload
        },
        setProfile: (state, action) => {
            state.profile = action.payload
            cache.setUserState(action.payload._id, action.payload)
        },
        logout: (state) => {
            state.status = 'idle'
            state.token = null
            state.profile = null
            state.progress = null
            state.error = null
            cache.resetCache()
        },
        resetError: (state) => {
            if (state.status === 'rejected') {
                state.status = 'idle'
                state.error = null
            }
        }
    }, 
    extraReducers: {

        //  asyncSignIn
        [_asyncSignIn.pending]: (state) => {
            state.status = 'pending'
            state.error = null
        },
        [_asyncSignIn.fulfilled]: (state, action) => {
            state.status = 'fulfilled'
            state.token = action.payload.token
            state.profile = action.payload.profile
            state.progress = {}
            action.payload.progress.forEach(item => {
                state.progress[item.lesson_id] = item.progress
            })
            cache.setUserState(state.token, state.profile, state.progress)
        },
        [_asyncSignIn.rejected]: (state, action) => {
            state.status = 'rejected'
            state.error = action.error
        },

        //  asyncSignUp
        [_asyncSignUp.pending]: (state) => {
            state.status = 'pending'
            state.error = null
        },
        [_asyncSignUp.fulfilled]: (state, action) => {
            state.status = 'fulfilled'
            state.token = action.payload.token
            state.profile = action.payload.profile
            state.progress = {}
            action.payload.progress.forEach(item => {
                state.progress[item.lesson_id] = item.progress
            })
            cache.setUserState(state.token, state.profile, state.progress)
        },
        [_asyncSignUp.rejected]: (state, action) => {
            state.status = 'rejected'
            state.error = action.error
        },

        //  asyncLoadUserState
        [asyncLoadUserState.pending]: (state) => {
            state.status = 'pending'
            state.error = null
        },
        [asyncLoadUserState.fulfilled]: (state, action) => {
            state.status = 'fulfilled'
            state.token = action.payload.token
            state.profile = action.payload.profile
            state.progress = {}
            action.payload.progress.forEach(item => {
                state.progress[item.lesson_id] = item.progress
            })
            cache.setUserState(state.token, state.profile, state.progress)
        },
        [asyncLoadUserState.rejected]: (state, action) => {
            state.status = 'rejected'
            state.error = action.error
            state.token = null
            state.profile = null
            state.progress = null
            cache.resetCache()
        },

        //  asyncSaveUserProgress
        [_asyncSaveUserProgress.fulfilled]: (state, action) => {
            const progress = action.payload
            progress.forEach(item => {
                state.progress[item.lesson_id] = item.progress
            })
            cache.setUserState(state.token, state.profile, state.progress)
        }
    }
})

export const selectAccessToken = state => state.userState.token
export const selectUserStateStatus = state => state.userState.status
export const selectUserStateError = state => state.userState.error
export const selectUserProfile = state => state.userState.profile
export const selectUserProgress = (state, lessonID) => state.userState.progress ? state.userState.progress[lessonID] : null
export const selectUserFullname = state => state.userState.profile ? `${state.userState.profile.first_name} ${state.userState.profile.last_name}` : null
export const {logout, resetError, setProfile, setAuthError} = slice.actions
export default slice.reducer


export const asyncSignIn = (username, password) => {

    var validatedValue = username
    if (/\d/.test(username) && username.length === 9) {
        validatedValue = `+998${username}`
    }

    return _asyncSignIn({username: validatedValue, password})
}
export const asyncSignUp = (email, phone_number, password, first_name, last_name) => _asyncSignUp({email, phone_number, password, first_name, last_name})
export const asyncSaveUserProgress = (lesson_id, progress) => _asyncSaveUserProgress([{lesson_id, progress}])