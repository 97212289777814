import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { selectCourseById, selectUnitById } from '../../../redux/entitiesSlice'

import Typography from '@material-ui/core/Typography'
import LinearProgress from './LineaProgress'
import CircularProgress from './CircularProgress'
import { Hidden } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        height: 72,
        paddingLeft: 50,
        paddingRight: 32,
        borderBottom: '1px solid #DCDCDC',
        gap: 32,
        '@media (max-width: 600px)':{
            gap: 0,
            paddingLeft: 16,
            paddingRight: 13
        }
    },
    // leftSection:{
    //     // width: '100%',
    //     marginRight: '15px'
    // },
    label: {
        fontSize: 14,
        fontWeight: 500,
    },
    linearProgress: {
        flex: 1,
        position: 'relative',
        top: -3,
        '@media (max-width:600px)':{
            flex: 'none'
            //    paddingRight: 15, 
        }
    }
})

const CourseProgress = (props) => {
    const {courseID, ref} = props
    const classes = useStyles()

    const course = useSelector(state => selectCourseById(state, courseID))
    const units = useSelector(state => course.units.map(unitID => selectUnitById(state, unitID)))
    const progress = useSelector(state => state.userState.progress)

    const totalLessonsCount = units.reduce((acc, curr) => acc + (Boolean(curr) ?  curr.lessons.length: 0), 0)
    let completedLessonsCount = 0
    units.forEach(unit => {
        if (unit) {
            unit.lessons.forEach(lessonID => {
                if (progress && progress[lessonID] >= 70) {
                    completedLessonsCount++
                }
            })
        }
    })

    return (
        <div className={classes.root}>
            <Hidden smDown>
                <Typography className={classes.label}>Sizning natijangiz</Typography>
            </Hidden>
            
            <div className={classes.linearProgress}>
                <LinearProgress completed={completedLessonsCount} total={totalLessonsCount} className={classes.linearProgress}/>
            </div>
            <CircularProgress completed={completedLessonsCount} total={totalLessonsCount}/>
        </div>
    )
}

CourseProgress.propTypes = {
    courseID: PropTypes.number.isRequired
}

export default CourseProgress