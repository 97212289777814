import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCourseById } from "../../redux/entitiesSlice";
import clsx from "clsx";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
import { selectUserProfile } from "../../redux/userStateSlice";
import { selectAuthFormState, showCode, showSignIn, showSignUp } from "../../redux/appStateSlice";

const author = {
  fullName: "Mixail Volinkin",
  photoUrl: "/images/teacher.jpg",
  profession: "Bodybuilding",
  studentsCount: 1278,
  coursesCount: 17,
  socialNetworks: [
    {
      id: 1,
      type: "instagram",
      username: "@volinkin.uz",
    },
  ],
  about:
    "Volinkin is an bodybuilding Has 7 years of work experience. It has trained high level students from primary education to IELTS\n\nIt is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here",
};

const useStyles = makeStyles({
  root: {
    minWidth: 246,
    maxWidth: 410,
    marginRight: 22,
    marginBottom: 10,
    position: "relative",
    // border: "1px solid #c68706",
    borderRadius: 8,
    boxShadow: "0px 4px 28px rgba(0, 0, 0, 0.25)",
  },
  media: {
    height: '100%',
   border: "7px solid ",
    zIndex: -1,
    borderRadius: 8,
    // backgroundColor: "#845FA0",
    // backgroundSize: "auto 164px",
    // backgroundPosition: "bottom",
  },
  content:{
    zIndex: 1,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
  },
  availability: {
    width: 100,
    height: 27,
    borderRadius: 4,
    position: "absolute",
    top: 13,
    left: 11,
    color: "white",
    fontWeight: 700,
    size: 14,
    lineHeight: "27px",
    textAlign: "center",
  },
  availability1: {
    width: 100,
    height: 27,
    borderRadius: 4,
    color: "white",
    fontWeight: 700,
    size: 14,
    // lineHeight: "27px",
    textAlign: "center",
  },
  title: {
    fontSize: 15,
    // lineHeight: "18px",
    // fontWeight: 700,
    // fontFamily: "Roboto",
    // letterSpacing: "0.035em",
    // fontStyle: "normal",
    color: "#fff",
  },
  author: {
    // fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "0em",
    marginTop: 11,
    color: "#fff",
  },
  description: {
    // fontFamily: "Roboto",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "0em",
    marginTop: 15,
    color: "#eee",
    whiteSpace: "pre-wrap",
  },
  ratingRoot: {
    marginTop: 15,
    display: "flex",
    alignItems: "center",
  },
  ratingNumber: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 13,
    lineHeight: "15px",
    color: "#A93E1C",
    marginRight: 4,
  },
  ratingCount: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    lineHeight: "14px",
    color: "#8A8A8A",
    marginLeft: 4,
  },
  footer: {
    marginTop: 30,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  joined: {
    fontSize: 12,
    lineHeight: "14px",
    color: "#8A8A8A",
  },
  price: {
    fontSize: 15,
    lineHeight: "18px",
  },
});

const CourseNutritionCard = ({ courseID, className, soon, item }) => {
  const classes = useStyles();
  const profile = useSelector(selectUserProfile);
  const dispatch = useDispatch();
  const show = useSelector(selectAuthFormState)
  console.log('profile CourseCard',profile);
  const history = useHistory();

  const handleClick = () => {
    
    if (profile?.isPayed) {
      history.push(`/nutrition/${item.category}`);
    } else if(profile) {
      // modal ko`rsatish kerak reg qilsin va pul to`lasin
      handleModalCode()
    } else {
      handleSignUp()
    }
  };

  const handleModalSignIn = () => {
    dispatch(showSignIn());
  };

  const handleModalCode = () => {
    dispatch(showCode());
  };

  const handleSignUp = () => {
    dispatch(showSignUp());
  };

  if (!item) {
    return <p>Yuklanmoqda...</p>;
  }
  // const { title, description, students_joined, students_rated, rating } =
  //   course;
  // console.log("course edvi", item);
  return (
    <Card className={clsx(classes.root, className)}>
      <CardActionArea onClick={handleClick}>
        <CardMedia
          style={{borderColor: item.borderColor, borderRadius: 8}}
          className={classes.media}
          image={item.image}
          title={item.name}
          component="img"
        />
        {/* {!soon && (
          <div
            className={classes.availability}
            style={{
              //backgroundColor: course.availability === 1 ? '#98BF4A' : '#E95C5C'
              backgroundColor: "#98BF4A",
            }}
          >
            Yangi
          </div>
        )}
        {soon && (
          <div
            className={classes.availability}
            style={{
              //backgroundColor: course.availability === 1 ? '#98BF4A' : '#E95C5C'
              backgroundColor: "red",
            }}
          >
            Tez orada
          </div>
        )} */}
        <CardContent className={classes.content}>
        <div
            className={classes.availability1}
            style={{
              //backgroundColor: course.availability === 1 ? '#98BF4A' : '#E95C5C'
              background: "#005aff",
            }}
          >
            <Typography className={classes.title} variant="h6" component="h2">
            {item.title}
          </Typography>
          </div>
          <Typography className={classes.author} variant="subtitle1">
            Muallifi: {item.author}
          </Typography>
          {/* <pre className={classes.description}>{description.split('\n')[0]}</pre> */}
          {/* <pre className={classes.description}>
            {
              item.desc
            }
          </pre> */}
          {/* <div className={classes.ratingRoot}>
            <Typography className={classes.ratingNumber}>{rating}</Typography>
            <Rating value={rating} readOnly precision={0.5} size="small" />
            <Typography className={classes.ratingCount}>
              ({students_rated} reytinglar)
            </Typography>
          </div> */}
          <div className={classes.footer}>
            <Typography className={classes.joined}>
              {/* O`qiyotganlar soni : {!soon ? "100" : "tez orada"} */}
              {/* {Intl.NumberFormat('en-US').format(students_joined)} */}
            </Typography>
            {/* <Typography className={classes.price} color="primary">
                        {Intl.NumberFormat('en-US').format(price_amount)}
                        &nbsp;
                        {price_unit}
                    </Typography> */}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CourseNutritionCard.propTypes = {
  courseID: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default CourseNutritionCard;
