import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store'
import {Provider} from 'react-redux'
import {BrowserRouter as Router} from 'react-router-dom'
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const theme = createMuiTheme({
  palette: {
      primary: {
          main: '#1752e0'
      }
  },
  typography: {
    fontFamily: ['Montserrat']
  }
})


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={theme}>
          <App/>
        </ThemeProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


