import { makeStyles, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useParams } from "react-router";

const useStyles = makeStyles({
  root: {
    display: "flex",
    backgroundColor: "white",
  },
  loadingRoot: {
    width: "100%",
    height: "100vh",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  main: {
    flex: "1 1 880px",
    marginBottom: 14,
    backgroundColor: "white",
    "@media (max-width: 600px)": {
      marginTop: 7,
    },
  },
  mainOver: {
    margin: "0",
    "@media (max-width: 600px)": {
      margin: "0 16px",
    },
  },
  boxCard: {
    height: 55
  },
  section: {
    padding: "0 34px 0 52px",
    "@media (max-width:600px)": {
      padding: "10px",
    },
  },
  rightBlock: {
    width: 320,
  },
  videoContainer: {
    width: "100%",
  },
  title: {
    fontWeight: "bold",
    marginLeft: 15,
    fontFamily: "Roboto",
    letterSpacing: "0.035em",
    lineHeight: "133.69%",
  },
  tabPlan: {
    display: "none",
  },
  "@media (max-width: 960px)": {
    tabPlan: {
      display: "block",
    },
  },
  disableTextTransform: {
    textTransform: "none",
    padding: 0,
    fontSize: 17,
    "@media (max-width: 600px)": {
      fontSize: 15,
      marginLeft: 20,
    },
  },
  accordionDetailsItem: {
    display: "flex",
    alignItems: "center",
    gap: 0,
    borderBottom: "1px solid #e9d1a0",
  },
  listItemSelected: {
    backgroundColor: "aliceblue",
  },
});


const PitMassa = ({classes}) => {
  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.boxCard}></div>
        <div className={classes.section}>
          <Typography style={{fontWeight: "bold", fontSize: 20}}>Питание на массу</Typography>
          <Typography style={{fontWeight: "bold", fontSize: 16}}>Завтрак: </Typography>
          <Typography>
            10та тухум 2 сариги бн кайнатилаган еки ковурилган 100-150гр Овсяный
            каша Свежий сабзавотлар (Салат)
            <Typography style={{fontWeight: "bold", fontSize: 16}}>2 завтрак:</Typography>
            100гр гуруч кайнатилган 200гр Товукни фильеси (Грудка) 1та олма
          </Typography>
          <Typography  style={{fontWeight: "bold", fontSize: 16}}>Обед: </Typography>
          <Typography>
            300гр мол гушти, Товук, балик 100гр макарон еки перловка Сабзавотлар
            еки салат Ачичук
            <Typography style={{fontWeight: "bold", fontSize: 16}}>Полдиник:</Typography>
            1та протеиновый батончик 2та банан
          </Typography>
          <Typography style={{fontWeight: "bold", fontSize: 16}}>Ужин: </Typography>
          <Typography>
            300гр Балик еки Гушт 2та кайнатилган картошка Свежий сабзавотлар еки
            Салат
          </Typography>
          <Typography style={{fontWeight: "bold", fontSize: 16}}>2 Ужин : </Typography>
          <Typography>
            250гр творог кефир бн 1та олма еки банан
            <Typography style={{fontWeight: "bold", fontSize: 16}}>Етишдан олдин :</Typography>
            2та порция протеин
          </Typography>
          <Typography></Typography>
        </div>
      </div>
    </div>
  );
};
const PitSushka = ({classes}) => {
  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.boxCard}></div>
        <div className={classes.section}>
          <Typography  style={{fontWeight: "bold", fontSize: 20}}>Питание на сушку</Typography>
          <Typography  style={{fontWeight: "bold", fontSize: 16}}>Завтрак: </Typography>
          <Typography>
          7-10та тухум зайтун егида ковурилган
100гр овсяный каша сувда сахорозаменитель бн
Сабзавотлар (Салат)
            <Typography  style={{fontWeight: "bold", fontSize: 16}}>2 завтрак:</Typography>
            1та протеиновый батончик ва 1та яшил олма
          </Typography>
          <Typography style={{fontWeight: "bold", fontSize: 16}}>Обед: </Typography>
          <Typography>
          Парланган еки зайтун егида ковурилган 250-300гр Гушт, Товук, еки Балик
          Парланган еки свежий сабзавотлар (Салат) 

            <Typography style={{fontWeight: "bold", fontSize: 16}}>Полдиник:</Typography>
            200 гр гушт, товук, еки балик
            1та яшил олма (Семеренка) 

          </Typography>
          <Typography style={{fontWeight: "bold", fontSize: 16}}>Ужин: </Typography>
          <Typography>
          250-300гр Гушт, Товук, еки Балик парланган еки зайтун егида ковурилган
          Сабзавотлар (Салат) 
          </Typography>
          <Typography style={{fontWeight: "bold", fontSize: 16}}>2 Ужин : </Typography>
          <Typography>
          10-15та тухумни оки пиез бн зайтун егда ковурилган
            <Typography style={{fontWeight: "bold", fontSize: 16}}>Етишдан олдин :</Typography>
            Протеин Изолят 2та порция сувда
          </Typography>
        </div>
      </div>
    </div>
  );
};
const KursMassa = ({classes}) => {
  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.boxCard}></div>
        <div className={classes.section}>
          <Typography style={{fontWeight: "bold", fontSize: 20}}>Курс на массу + Спорт пит:</Typography>
          <Typography style={{fontWeight: "bold", fontSize: 16}}>Фармакология </Typography>
          <Typography>
          Гормон роста 0,5ед эрталаб оч коринга 0,5ед етишдан олдин
            <Typography>Примаболан бн Болденон 1мгдан Душанба-Пайшанба</Typography>
            Тестестерон Энантат 1мг 2 хафтада 1 марта
          </Typography>
          <Typography>Туринабол кунига 4та таблетка 2та завтракдан кейинг 2та тренировкадан олдин </Typography>
          <Typography>
          Клофит 0,5таб хар куни етишдан олдин
            <Typography style={{fontWeight: "bold", fontSize: 16}}>Спорт питание:</Typography>
            Протеин кунига 3 махал 2 порциядан завтракдан кейинг, тренировкадан кейинг, етишдан олдин
          </Typography>
          <Typography>Л-Аргинин кунига 2та порция завтракдан кейинг ва тренировкадан олдин </Typography>
          <Typography>
          БЦАА 2та порция тренировка пайт 
          </Typography>
          <Typography>Глютамин 1та порция етишдан олдин </Typography>
          <Typography>
          Омега 3 кунига 4та капсула 2та завтракдан кейинг 2та етишдан олдин
          </Typography>
          <Typography>Витаминый комплекс:</Typography>
          <Typography>завтракдан кейинг 1та порция</Typography>
          <Typography>Кардио магнил 75мг 1та таб хар куни етишдан олдин</Typography>
        </div>
      </div>
    </div>
  );
};

const KursSushka = ({classes}) => {
  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.boxCard}></div>
        <div className={classes.section}>
          <Typography style={{fontWeight: "bold", fontSize: 20}}>Курс на Сушку + Спорт пит</Typography>
          <Typography>Гормон роста 0,5ед эрталаб 0,5ед етишдан олдин</Typography>
          <Typography>
          Тренбалон Ацетат бн Драстолон пропионат (Мастерон) 1млгдан кунаро
            <Typography>Станазалол кунига 4та таб 2та завтракдан кейинг 2та тренировкадан олдин</Typography>
            Оксандролон кунига 4та таб 2та завтракдан кейинг 2та тренировкадан олдин
          </Typography>
          <Typography>Цитомель (Т3) хар куни 1та таб завтракдан кейинг</Typography>
          <Typography>
          Л-Тироксин кунига 1та таб завтракдан кейинг
            <Typography>Клофит 0,5таб хар куни етишдан олдин</Typography>
            Кардио магнил 75мг 1та таб хар куни етишдан олдин
          </Typography>
          <Typography style={{fontWeight: "bold", fontSize: 16}}>Спорт Питание </Typography>
          <Typography>
          Л-Карнитин 1та порция завтракдан олдин 1та порция тренировкадан олдин
          </Typography>
          <Typography>Л-Аргинин 2та порция завтракдан кейинг 2та порция тренировкадан олдин</Typography>
          <Typography>
          Липо 6 тренировкадан олдин 2та таблетка
            <Typography>Омега 3 4та капсула эрталаб 4та капсула етишдан олдин</Typography>
            Витаминый комплекс 1та порция етишдан олдин
          </Typography>
          <Typography>Д-3 завтракдан кейинг 1та таб</Typography>
          <Typography>Л-Глютамин 1та порция завтракдан кейинг 1та порция тренировкадан кейинг</Typography>
          <Typography>БЦАА кунига 6та порция 2та порция завтракдан кейинг, 2та порция тренировка пайт, 1та порция тренировкадан кейинг 1та порция етишдан олдин</Typography>
          <Typography>Протеин Изолят кунига 3 махал 1та порц завтракдан кейинг, 2та порц тренировкадан кейинг, 1та порц етишдан олдин.</Typography>
        </div>
      </div>
    </div>
  );
};

const PKT = ({classes}) => {
  return (
    <div className={classes.root}>
    <div className={classes.main}>
      <div className={classes.boxCard}></div>
      <div className={classes.section}>
        <Typography>ПКТ (Чистка)</Typography>
        <Typography>Тивортин капельница 5 марта олинади кунора 10кун давомида</Typography>
        <Typography>
        Гептрал 400мг 2та ампулани биттада 500мл физрастворга арилаштириб 5 марта капельница олинади кунора 10кун давомида
        </Typography>
        <Typography>Милдронат 2та ампула внутривенный килинади сразу </Typography>
        <Typography>Тивортин ва Гептралдан кейинг кетма кет хаммаси!</Typography>
        <Typography>
        Урсусан 3та таб хар куни етишдан олдин Тугагунча
Провирон 50-100мг 1ой давомида хар куни етишдан олдин
        </Typography>
        <Typography>Тутукон 1ой давомида 1та порциядан етишдан олдин </Typography>
        <Typography>Канефрон 1та таб етишдан олдин 1ой давомида  </Typography>
        <Typography>
        Трибулус хар куни отишдан олдин 2та таблетка Тугагунча 
        </Typography>
        <Typography>Капельницалани тугатиб булганимиздан кейинг </Typography>
        <Typography>
        Гептрал 400мг таблетка 1та таблетка завтракдан кейинг 20кун давомида
        </Typography>
        <Typography>Анимал Пак 1та порциядан 30кун давомида </Typography>
        <Typography>Ганадатропин 1000лиги  </Typography>
        <Typography>кунора енбошдан укол килинади етишдан олдин 20кун давомида </Typography>
      </div>
    </div>
  </div>
  );
};

function NutritionPage() {
  const classes = useStyles();
  const params = useParams();

  const nutrition = params["category"];

  console.log("NutritionPage", nutrition);

  if (nutrition === "pit-massa") {
    return <PitMassa classes={classes} />;
  } else if (nutrition === "pit-sushka") {
    return <PitSushka classes={classes} />;
  } else if (nutrition === "kurs-massa") {
    return <KursMassa classes={classes} />;
  } else if (nutrition === "kurs-sushka") {
    return <KursSushka classes={classes} />;
  } else if (nutrition === "pkt") {
    return <PKT classes={classes} />;
  }
}

export default NutritionPage;
