import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { selectLessonById, selectVideoById } from "../../redux/entitiesSlice";
import Button from "@material-ui/core/Button";
import VideoPlayer from "./VideoPlayer";
import { getVideoID } from "../../APIs/courses";
import axios from "axios";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
  },
  noVideo: {
    width: "100%",
    minHeight: "480px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
    color: "white",
  },
  videoPlayer: {
    width: "100%",
    borderTop: "5px solid black",
    marginTop: 10,
    // minHeight: '480px',
  },
  footer: {
    height: "30px",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 20,
  },
  button: {
    zIndex: 1000,
    // borderRadius: 0,
    borderColor: "#d8e5f1",
  },
  selected: {
    backgroundColor: "#d8e5f1",
  },
  disableTextTransform: {
    textTransform: "none",
  },
});

const LessonVideo = (props) => {
  const [data, setData] = useState(null);

  const classes = useStyles();
  const { lessonID, className } = props;
  console.log("lessonID LessonVideo", lessonID);
  const lesson = useSelector((state) => selectLessonById(state, lessonID));
  const videoIDs = lesson ? lesson.videos : [];
  const videos = useSelector((state) =>
    videoIDs.map((videoID) => selectVideoById(state, videoID))
  );
  const [currentVideoIdx, setCurrentVideoIdx] = useState(0);

  // useEffect( async() => {
  //   if (lessonID !== null) {
  //     await axios.get(`http://139.59.174.35/api/video/bycourseItem/${id}`)
  //       .then((res) => {
  //         // setData(res);
  //         console.log("res getVideoID", res);
  //       })
  //       .catch((err) => {
  //         console.log("catch err", err);
  //       });
  //   }
  // }, [lessonID]);

  useEffect(() => {
    setCurrentVideoIdx(0);
  }, [lessonID]);

  if (lessonID === null) {
    return (
      <div className={classes.noVideo}>
        <h1>Darsliklardan birini tanlang</h1>
      </div>
    );
  }

  const currentVideo = videos[currentVideoIdx];
  let src = null;
  let isPublic = null;
  if (currentVideo) {
    console.debug(
      "current video: ",
      currentVideo.display_name,
      ", totalVideos=",
      videos.length
    );
    src = `https://drive.google.com/uc?id=${currentVideo.cloud_path}&export=download`;
    isPublic = currentVideo["is_public"];
  }

  return (
    <div className={clsx(classes.root, className)}>
      <VideoPlayer
        src={"https://volinkin.uz" + lessonID}
        className={classes.videoPlayer}
        controls
      />
      <div className={classes.footer}>
        {videos.length > 1 &&
          videos.map((v, idx) => (
            <Button
              classes={{ label: classes.disableTextTransform }}
              key={v.id}
              variant="outlined"
              onClick={() => setCurrentVideoIdx(idx)}
              className={clsx(
                classes.button,
                idx === currentVideoIdx && classes.selected
              )}
            >
              Video {v.position}
            </Button>
          ))}
      </div>
    </div>
  );
};

LessonVideo.propTypes = {
  className: PropTypes.string,
};

export default LessonVideo;
