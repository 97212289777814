import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { selectExerciseById, selectLessonById } from '../../../redux/entitiesSlice'
import { asyncSaveUserProgress } from '../../../redux/userStateSlice'
import getStarsCount from "../../../utils/getStarsForProgress";
import ExerciseQuiz from './ExerciseQuiz';
import ExerciseFillTheGaps from './ExerciseFillTheGaps'
import { makeStyles } from '@material-ui/core'

export const ExerciseCategory = {
    Quiz: 0,
    CompleteToBe: 1,
}

const useStyles = makeStyles({
    main:{
        margin: 0,
        '@media (max-width: 600px)':{
            margin: '0 20px 30px 20px'
        }
    }
})

const ExercisesList = ({className, lessonID}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const lesson = useSelector(state => selectLessonById(state, lessonID))
    const exercisesIDs = lesson ? lesson.exercises : []
    const exercises = useSelector(state => exercisesIDs.map(exerciseID => selectExerciseById(state, exerciseID))).sort((a, b) => a.position - b.position)
    const [quizProgress, setQuizProgress] = useState(0)
    const [completeSentenceProgress, setCompleteSentenceProgress] = useState(0)
    const [error, setError] = useState(null)
    const quiz = exercises.filter(e => e.type === ExerciseCategory.Quiz)
    const completeSentence = exercises.filter(e => e.type === ExerciseCategory.CompleteToBe)

    useEffect( () => {
        try {
            const divider = Math.max(Math.min(quiz.length, 1) + Math.min(completeSentence.length, 1), 1)
            const progress = (quizProgress + completeSentenceProgress) / divider
            const canPassQuiz = quiz.length === 0 || getStarsCount(quizProgress) > 0
            const canPassCompleteSentence = completeSentence.length === 0 || getStarsCount(completeSentenceProgress) > 0

            if (canPassQuiz && canPassCompleteSentence) {
                console.debug('[save-user-progess] lessonID:', lessonID, 'progress:', progress, 'divider:', divider)
                if (progress > 100) {
                    let e = `progress > 100: ${progress}, ${divider}, ${quiz.length}, ${completeSentence.length}`
                    console.error('ExerciseList:', e)
                    setError(e)
                }
                else {
                    dispatch(asyncSaveUserProgress(lessonID, progress))
                }
            }
            else {
                console.log('[save-user-progess] skipped(',
                'canPassQuiz:', canPassQuiz, 
                'canPassCompleteSentence:', canPassCompleteSentence, 
                'progress:', progress,
                'divider:', divider, ')')
            }
        }
        catch(e) {

        }

    }, [quizProgress, completeSentenceProgress])

    if (exercises.length === 0) {
        return null
    }
    
    const handleSaveQuizProgress = (progress) => {
        setQuizProgress(progress)
    }

    const handleSaveCompleteSentenceProgress = (progress) => {
        setCompleteSentenceProgress(progress)
    }

    return (
        <div className={classes.main}>
            {error && <h1 style={{color: 'red'}}>If you see this message, please take a screenshot and send administrator: {error}</h1>}
            {exercises.map(e => {
                switch (e.type) {
                    case ExerciseCategory.Quiz:
                        return <ExerciseQuiz key={e.id} lessonID={lessonID} exercise={e} onSaveProgress={handleSaveQuizProgress}/>
                    case ExerciseCategory.CompleteToBe:
                        return <ExerciseFillTheGaps key={e.id} lessonID={lessonID} exercise={e} onSaveProgress={handleSaveCompleteSentenceProgress}/>
                    default:
                        return null
                }
            })}
        </div>
    )
}

ExercisesList.propTypes = {
    lessonID: PropTypes.number,
    className: PropTypes.string
}

export default ExercisesList