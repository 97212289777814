import axios from 'axios'
import parseResponse from '../utils/parseResponse'

export const getSubscription = async (accessToken, courseID, planID) => {
    return parseResponse(await axios.get('https://api.edvi.uz/subscription?course=' + courseID + '&plan=' + planID, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    }))
}

export const getUserProfile = async (accessToken) => {
    return parseResponse(await axios.get('https://api.edvi.uz/my-profile', {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    }))
}

export const setUserProfile = async (accessToken, profile) => {
    return parseResponse(await axios.post('https://api.edvi.uz/my-profile', profile, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    }
    )
    )
}

export const getUserProgress = async (accessToken) => {
    return parseResponse(await axios.get('https://api.edvi.uz/my-progress', {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    }))
}

export const setUserProgress = async (accessToken, progress) => {
    return parseResponse(await axios.post('https://api.edvi.uz/my-progress', progress, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    }))
}