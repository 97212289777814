import axios from 'axios'
import parseResponse from '../utils/parseResponse'

export const getCourses = async () => {
    return parseResponse(await axios.get('https://volinkin.uz/api/course/getSort?category=begginer&tag=""'))
}

export const getCourseTrenka = async (category) => {
    return parseResponse(await axios.get(`https://volinkin.uz/api/course/getSort?category=${category}&tag=""`))
}

export const getCoursePitaniya = async () => {
    return parseResponse(await axios.get('https://volinkin.uz/api/course/getSort?category=begginer&tag=""'))
}

export const getCoursesItem = async (id) => {
    console.log('getCoursesItem id', id);
    return parseResponse(await axios.get(`http://yulduzrajabova.uz/api/courseItem/byCourse/${id}`))
}

export const getVideoID = async (id) => {
    console.log('getVideoID id', id);
    return parseResponse(await axios.get(`http://192.168.2.142:8080/api/video/bylessonID/${id}`))
}

export const getCourseFull = async (courseID, accessToken) => {
    return parseResponse(await axios.get('https://api.edvi.uz/courses/' + courseID + '/v2', {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    }))
}